// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Input,
  Select,
  Grid,
  MenuItem,
  styled,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Button,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Add,
  ArrowBackIos,
  Check,
  ExpandMore,
  PersonAddOutlined,
  Remove,
  Search,
} from "@material-ui/icons";
import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 800,
    },
  },
});

const SortInput = styled(Select)({
  color: "#237182",
  fontWeight: 600,
  fontFamily: "Poppins",
  marginLeft: "0.5rem",
  "& .MuiSelect-select.MuiSelect-select": {
    padding: "0 24px 0 0",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "#F0F5F7",
  },
  "& .MuiSelect-icon": {
    color: "#237182",
  },
});

const CustomStepper = styled(Stepper)({
  backgroundColor: "#F0F5F7",
  fontFamily: "Poppins, sans-serif",
  position: "absolute",
  left: 0,
  right: 0,
  width: "35vw",
  margin: "0 auto",
  padding: "0",
  "& .MuiStepConnector-line": {
    borderColor: "#94A3B8",
    borderTopWidth: "2px",
  },
  "& .MuiStepConnector-active .MuiStepConnector-line": {
    borderColor: "#0F172A",
  },
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#0F172A",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
  },
  "& .MuiStepIcon-text": {
    fontSize: "1rem",
    fontFamily: "Poppins",
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 0,
  },
  "& .MuiTypography-root": {
    paddingLeft: "0.5rem",
    fontSize: "0.875rem",
    color: "#0F172A",
    fontWeight: 600,
  },
});

const BackButtonBox = styled(Box)({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: 600,
  "& p": {
    fontSize: "0.875rem",
    color: "#334155",
    fontWeight: 600,
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
    color: "#334155",
  },
});

const NextButton = styled(Button)({
  background: "#237182",
  padding: "10px 16px",
  borderRadius: "8px",
  color: "#FFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "1rem",
  textTransform: "none",
  "&:disabled": {
    background: "none",
    color: "#64748B",
  },
  "&:hover": {
    backgroundColor: "#237182",
  },
});

export default class ProjectPortfolioShells extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount(): any {
    this.setState({ activeStep: 1 });
  }

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "2rem",
      height: "2rem",
      fontFamily: "Poppins, sans-serif",
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <Box bgcolor="#F0F5F7" px="2.5rem" py="2rem">
            <Box style={webStyle.pageTitle}>
              <BackButtonBox onClick={this.goBack}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </BackButtonBox>
              <CustomStepper nonLinear activeStep={this.state.activeStep}>
                {this.getSteps().map((placeholder, index) => (
                  <Step
                    key={placeholder}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    <ActiveStepLabel
                      StepIconComponent={(props) => (
                        <this.CustomStepIcon
                          {...props}
                          active={this.state.activeStep === index}
                          completed={this.state.activeStep > index}
                        />
                      )}
                    >
                      {this.state.activeStep === index && placeholder}
                    </ActiveStepLabel>
                  </Step>
                ))}
              </CustomStepper>
              <Box style={webStyle.buttonsBlock}>
                <Box style={webStyle.addMemberBtn}>
                  <PersonAddOutlined style={webStyle.addMemberIcon} />
                  <Typography style={webStyle.addMemberText}>
                    Add Member
                  </Typography>
                </Box>
                <Button style={webStyle.saveDraftBtn}>Save Draft</Button>
                <NextButton
                  disabled={
                    !this.state.shellsSubstructures.some(
                      (item) => item.amount > 0
                    )
                  }
                  data-test-id="next-btn"
                  onClick={() =>
                    this.state.activeStep === 1
                      ? this.setState({ activeStep: 2 })
                      : console.log("Navigate to rooms here")
                  }
                >
                  Next
                </NextButton>
              </Box>
            </Box>

            <Box style={webStyle.topBox}>
              <Input
                startAdornment={<Search style={webStyle.searchIcon} />}
                style={webStyle.searchInput}
                placeholder="Search here..."
                onChange={(e) => this.handleSearchInputChange(e.target.value)}
                value={this.state.searchInputText}
                disableUnderline
                data-test-id="search-input"
              />
              <Box style={webStyle.topBox}>
                <Typography style={webStyle.sortText}>Sort by:</Typography>
                <SortInput
                  disableUnderline
                  IconComponent={ExpandMore}
                  defaultValue={1}
                >
                  <MenuItem value={1}>Alphabetical</MenuItem>
                </SortInput>
              </Box>
            </Box>
            <Grid container spacing={2} xs={12} style={webStyle.itemsGrid}>
              {this.state.filteredItems.map((item) => (
                <Grid
                  item
                  md={3}
                  sm={6}
                  xs={12}
                  style={{ marginTop: "1.5rem" }}
                  key={item.id}
                >
                  <Box style={webStyle.gridItem}>
                    <img src={item.logo} />
                    <Typography style={{ margin: "1rem" }}>
                      {item.name}
                    </Typography>
                    <Box style={webStyle.counterBox}>
                      <Remove
                        style={webStyle.counterButton}
                        onClick={() =>
                          this.handleAmountChange(item.id, "remove")
                        }
                        data-test-id="remove-btn"
                      />
                      <Typography>{item.amount}</Typography>
                      <Add
                        style={webStyle.counterButton}
                        onClick={() => this.handleAmountChange(item.id, "add")}
                        data-test-id="add-btn"
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  pageTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  buttonsBlock: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  addMemberBtn: {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
  },
  addMemberIcon: {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  addMemberText: {
    fontWeight: 600,
    color: "#237182",
  },
  saveDraftBtn: {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none" as "none",
  },
  topBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchInput: {
    border: "1px solid #C4CBD4",
    padding: "0 16px",
    background: "#FCFDFE",
    borderRadius: "8px",
  },
  searchIcon: {
    color: "#DADADA",
    width: "20px",
    height: "20px",
    marginRight: "0.5rem",
  },
  sortText: {
    fontSIze: "0.875rem",
    color: "#334155",
  },
  gridItem: {
    background: "white",
    borderRadius: "8px",
    textAlign: "center" as "center",
    padding: "1.75rem 0",
  },
  counterBox: {
    display: "flex",
    alignItems: "center" as "center",
    justifyContent: "center",
    gap: "1rem",
  },
  counterButton: {
    width: "20px",
    height: "20px",
    color: "#0F172A",
    cursor: "pointer",
  },
  itemsGrid: {
    overflowY: "auto" as "auto",
    maxHeight: "calc(100vh - 34px)",
    margin: "0px",
  },
};

// Customizable Area End
