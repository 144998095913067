import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean |File | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
  placeholder:string;
}



export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

const formFields = [
  "first_name",
  "lastName",
  "countryCode",
  "phoneNumber",
  "about_me",
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  companyNumberError:boolean;
  companyNumberErrorMsg:string;
  vatError:boolean;
 vatErrorMsg:string;
  streetNameError: boolean;
  streetNameErrorMsg: string;
  cityError:boolean;
cityErrorMsg:string;
  zipcodeError: boolean,
  zipcodeErrorMsg: string,
  stateError: boolean,
  stateErrorMsg: string,
  countryError: boolean,
  countryErrorMsg: string,
  first_name:string;
  firstNameError:boolean;
  firstNameErrorMsg:string;
  lastName:string;
  lastNameError:boolean;
  lastNameErrorMsg:string;
  countryCode:string;
  countryCodeError: boolean;
  countryCodeErrorMsg: string;
  phoneNumber:string;
  phoneNumberError:boolean;
  phoneNumberErrorMsg:string;
  companyName:string;
  companyNameError: boolean,
  companyNameErrorMsg: string,
  aboutMeError:boolean,
  aboutMeErrorMsg:string,
  companyLogo: any;
  httpBodyPersonal: any;
  companyLogoError: boolean,
  companyLogoErrorMsg: string,
  inputKey: number,
  company_number:string;
  formComplete: boolean;
  vat:string;
  street_name:string;
  city:string;
  zip_code:string;
  state:string;
  country:string;
  validations: { validateFirstName: boolean; validateLastName: boolean; validatePhoneNumber: boolean; validateCompanyName: boolean; validateCompanyLogo: boolean; validateCountryCode: boolean
    validateCompanyNumber: boolean,
    validateAboutMe:boolean,
    street_nameMatch: boolean,
    validateVAT: boolean,
    cityMatch: boolean,
    zipcodeMatch: boolean,
    stateMatch: boolean,
    countryMatch: boolean,

  };
  imageData: {
    file_name: string;
    content_type: string;
    image: string;
    size:number;
  };
  editImageData:{
    file_name: string;
    content_type: string;
    image: string;
    size:number | string;
  };
  errors:{
    first_name:string;
    lastName:string;
    countryCode:string;
    phoneNumber:string;
    companyName:string;
    // companyLogo: File | null;
    company_number:string;
    vat:string;
    street_name:string;
    city:string;
    zip_code:string;
    state:string;
    country:string;
    firstNameError: string;
    lastNameError: string;
    phoneNumberError: string;
    companyNameError: string;
    aboutMeError:string;
    companyLogoError: string;
    countryCodeError: string
    companyNumberError:string,
    vatError:string,

  }
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  fullName: string;
  email: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  selectedCountry:string,
  selectedState:string,
  selectedCity:string
  editPersonalInformation: {
    first_name: string,
    last_name: string,
    phone_number: string,
    role_id: string,
    job_title: string,
    about_me: string,
    country_code: string,
    company_logo: string | File,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
  };
  editDetailToggle: boolean,
  editError: {
    first_name: string,
    last_name: string,
    phone_number: string,
    role_id: string,
    job_title: string,
    about_me: string,
    country_code: string,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
  },
  editPicToggle:boolean,
  previewImage:string,
  messageToggle:boolean,
  tostifymessage:string,
  status:string
  toggleEditPersonalInfoAndBusinessInfo:boolean,
  popUpToggle:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableClientProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  postCreateMemberCallId:string= "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;
  getCustomizableBusinessProfileCallId:string="";
  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";
  createPersonalProfilesApiCallID: any;
  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getPersonalInformation:string="";
  editPersonalInformationId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedCountry:'',
      selectedState:'',
      selectedCity:'',
      vatError:false,
      vatErrorMsg:'',
       companyNumberError:false,
       companyNumberErrorMsg:'',

      streetNameError: false,
      streetNameErrorMsg: '',
       cityError:false,
     cityErrorMsg:'',
      zipcodeError: false,
      zipcodeErrorMsg: '',
      stateError: false,
      stateErrorMsg: '',
     countryError:false,
      countryErrorMsg: '',
      first_name:"",
      firstNameError:false,
      firstNameErrorMsg:"",
      lastName:"",
      lastNameError:false,
      lastNameErrorMsg:"",
      countryCode:"+44",
      countryCodeError: false,
      countryCodeErrorMsg: '',
      phoneNumber: "",
      phoneNumberError:false,
      phoneNumberErrorMsg:'',
      companyName:"",
      aboutMe:'',
      companyNameError: false,
      aboutMeError:false,
      aboutMeErrorMsg:'',
      companyNameErrorMsg: '',
      companyLogo: null,
      httpBodyPersonal: {},
      inputKey: Date.now(),
      companyLogoError: false,
      companyLogoErrorMsg: '',
      company_number:"",
      formComplete: false,
      validations:
        { validateFirstName: false, 
        validateLastName: false,
        validatePhoneNumber: false,
        validateCompanyName: false,
        validateCompanyLogo: false,
          validateCountryCode: false ,
        validateCompanyNumber: false,
        validateAboutMe:false,
        street_nameMatch: false,
        validateVAT: false,
        cityMatch: false,
        zipcodeMatch: false,
        stateMatch: false,
        countryMatch: false,
      },
      imageData: {
        file_name: '',
        content_type: '',
        image: '',
        size: 0
      },
      editImageData:{
        file_name: "",
        content_type: "",
        image: "",
        size:""
      },
      vat:"",
      street_name:"",
      city:"",
      zip_code:"",
      state:"",
      country:"",
      errors:{
        first_name:"",
        lastName:"",
        countryCode:"",
        phoneNumber: "",
        companyName:"",
        // companyLogo:null,
        company_number:"",
        vat:"",
        street_name:"",
        city:"",
        zip_code:"",
        state:"",
        aboutMeError:"",
        country:"",
        firstNameError: "",
        lastNameError: "",
        phoneNumberError: '',
        companyNameError: '',
        companyLogoError: '',
        countryCodeError: '',
        companyNumberError:"",
        vatError:"",


      },
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      fullName: "",
      email: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      editPersonalInformation: {
        first_name: "",
        last_name: "",
        phone_number: "",
        role_id: "",
        job_title: "",
        about_me: "",
        country_code: "",
        company_logo: "",
        company_name: "",
        company_number: "",
        vat: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: ""
      },
      editDetailToggle: true,
      editError: {
        first_name: "",
        last_name: "",
        phone_number: "",
        role_id: "",
        job_title: "",
        about_me: "",
        country_code: "",
        company_name: "",
        company_number: "",
        vat: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: ""
      },
      editPicToggle:true,
      previewImage:"",
      messageToggle:false,
      tostifymessage:"",
      status:"",
      toggleEditPersonalInfoAndBusinessInfo:true,
      popUpToggle:false
      // Customizable Area End
    };
    // Customizable Area Start
    // this.checkToken = this.checkToken.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // this.checkToken();
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (this.checkSession(message)) return;
    runEngine.debugLog("Message Received", message);

    // Customizable Area End
  }

  // Customizable Area Start


 
  updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ first_name: value }, this.validateFirstName);
  };

  validateFirstName = () => {
    const { first_name } = this.state;
    let firstNameError = false;
    let firstNameErrorMsg = '';
    let validations = { ...this.state.validations, validateFirstName: true };
  
    if (!first_name) {
      firstNameError = true;
      firstNameErrorMsg = 'Enter the first name';
      validations.validateFirstName = false;
    }
    else if (!/^[a-zA-Z0-9\s]+$/.test(first_name)) {
      firstNameError = true;
      firstNameErrorMsg = 'First name should contain aplhabets and numbers';
      validations.validateFirstName = false;
    } else {
      window.localStorage.setItem("first_name", this.state.first_name);
    }

    this.setState({
      firstNameError,
      firstNameErrorMsg,
      validations,
      errors: { ...this.state.errors, firstNameError: firstNameErrorMsg }
    }, this.validateForm);
  };


  updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ lastName: value }, this.validateLastName);
  };

  validateLastName = () => {
    const { lastName } = this.state;
    let lastNameError = false;
    let lastNameErrorMsg = '';
    let validations = { ...this.state.validations, validateLastName: true };

    if (!lastName) {
      lastNameError = true;
      lastNameErrorMsg = 'Enter the last name';
      validations.validateLastName = false;
    }
    else if (!/^[a-zA-Z0-9\s]+$/.test(lastName)) {
      lastNameError = true;
      lastNameErrorMsg = 'Last name should contain alphabets and numbers';
      validations.validateLastName = false;
    } else {
      window.localStorage.setItem("lastName", this.state.lastName);
    }

    this.setState({ lastNameError, lastNameErrorMsg, validations, errors: { ...this.state.errors, lastNameError: lastNameErrorMsg } }, this.validateForm);
  };

  updateCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ countryCode: event.target.value as string }, this.validateCountryCode);
  };

  validateCountryCode = () => {
    const { countryCode } = this.state;
    let countryCodeError = false;
    let countryCodeErrorMsg = '';

    if (!countryCode) {
      countryCodeError = true;
      countryCodeErrorMsg = 'Select the country code';
    }

    window.localStorage.setItem("countryCode", this.state.countryCode)
    this.setState({ countryCodeError, countryCodeErrorMsg }, this.validateForm);
  };


  updatePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length <= 10) {
      this.setState({ phoneNumber: value }, this.validatePhoneNumber);
    } else {
      this.setState({ phoneNumber: value.slice(0, 10) }, this.validatePhoneNumber); // Ensure only first 10 digits are considered
    }
  };


  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    let phoneNumberError = false;
    let phoneNumberErrorMsg = '';
    let validations = { ...this.state.validations, validatePhoneNumber: true };

    if (!phoneNumber) {
      phoneNumberError = true;
      phoneNumberErrorMsg = 'Enter the phone number';
      validations.validatePhoneNumber = false;
    }else if (phoneNumber.length !== 10) {
      phoneNumberError = true;
      phoneNumberErrorMsg = 'Phone number must be 10 digits';
      validations.validatePhoneNumber = false;
    } else {
      window.localStorage.setItem("phoneNumber", this.state.phoneNumber);
    }

    this.setState({ phoneNumberError, phoneNumberErrorMsg, validations }, this.validateForm);
  };

  updateAboutMe = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    this.setState({ aboutMe: value }, this.validateAboutMe);
  };

  validateAboutMe = () => {
    const { aboutMe } = this.state;
    let aboutMeError = false;
    let aboutMeErrorMsg = '';
    let validations = { ...this.state.validations, validateAboutMe: true };
  
    if (!aboutMe) {
        aboutMeError = true;
        aboutMeErrorMsg = 'Write about yourself';
      validations.validateAboutMe = false;
    } 
    else if (!/^[a-zA-Z0-9\s]+$/.test(aboutMe)) {
        aboutMeError = true;
      validations.validateAboutMe = false;
    } else {
      window.localStorage.setItem("aboutMe", this.state.aboutMe);
    }
  
    this.setState({ aboutMeError, aboutMeErrorMsg, validations }, this.validateForm);
  };
 
  getHelperText = (error: any, errorMsg: any) => {
    return error ? errorMsg : '';
  };

  validateForm = () => {
    const { first_name, lastName, countryCode, phoneNumber, aboutMe } = this.state;
    const formComplete = first_name !== '' && lastName !== '' && countryCode !== '' && phoneNumber !== '' && aboutMe !== '';
    this.setState({ formComplete });
  };

  // Customizable Area End
}
