// Customizable Area Start
import React from "react";
import {
  AppBar,
  Typography,
  Container,
  TextField,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Divider,
  Stepper,
  Step,
  StepIcon,
  StepLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  Check,
  ArrowBackIos
} from "@material-ui/icons";
import { addMember } from "./assets";
import WorldFlag from "react-world-flags";
import { Formik } from "formik";
import * as yup from "yup";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import "react-datepicker/dist/react-datepicker.css";

import CreateEditProjectPortfolioController, {
  Props,
  configJSON,
} from "./CreateEditProjectPortfolioController";
import Loader from "../../../components/src/Loader.web";

const FormContainer = styled(Container)({
  marginBlock: "20px",
  borderRadius: "8px",
  maxWidth: "-webkit-fill-available",
  backgroundColor: "#fff",
  marginInline: "20px",
  fontFamily: "'Poppins', sans-serif",
});

const Section = styled(Box)({
  marginBottom: "20px",
  padding: "20px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  fontFamily: "'Poppins', sans-serif",
});

const SaveButton = styled(Button)({
  backgroundColor: "#E0EDF0",
  borderRadius: "8px",
  border: "1px solid #C5E0E7",
  color: "#325962",
  marginRight: "13px",
  fontWeight: 600,
  fontSize: "16px",
  height: "44px",
  letterSpacing: 0,
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  "&:hover": {
    backgroundColor: "#C5E0E7",
  },
});
const SectionLabel = styled(Typography)({
  fontFamily: "'Poppins', sans-serif",
  color: "#0F172A",
  fontSize: "18px",
  fontWeight: 700,
  marginBottom: "15px",
});
const InputLabel = styled(Typography)({
  color: "#64748B",
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "'Poppins', sans-serif",
  marginBottom: "2px",
});

const DisabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#F1F5F9",
  color: "#64748B",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "#F1F5F9",
  },
});

const EnabledNextButton = styled(Button)({
  fontFamily: "'Poppins', sans-serif",
  backgroundColor: "#237182",
  color: "#fff",
  textTransform: "none",
  fontSize: "16px",
  fontWeight: 600,
  width: "69px",
  height: "44px",
  padding: "10px 16px 10px 16px",
  borderRadius: "8px",
  "&:hover": {
    backgroundColor: "#237182",
  },
});

const Root = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  width: "40%",
  backgroundColor: "#F0F5F7",
});

const ActiveStepLabel = styled(StepLabel)({
  "& .MuiStepIcon-root.MuiStepIcon-active": {
    color: "#0F172A",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root.MuiStepIcon-completed": {
    color: "#34D399",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepIcon-root": {
    color: "#94A3B8",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
  },
  "& .MuiStepLabel-label.MuiStepLabel-active": {
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    color: "#0F172A",
  },
});

const Wrapper = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
});

const formSchema = (conditionalValidate: boolean) => 
  yup.object().shape({
    projectName: yup
      .string()
      .matches(/^[a-zA-Z\d]+$/, "Project name is invalid")
      .required("Please enter project name"),
    firstName: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "First name is invalid")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter first name"),
        otherwise: yup.string(),
      }),

    lastName: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "Last name is invalid")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter last name"),
        otherwise: yup.string(),
      }),

    postCode: yup.string().required("Please enter post code"),
    country: yup.string().required("Please select country"),
    streetName: yup.string().required("Please enter street name"),

    city: yup
      .string()
      .matches(/^[a-zA-Z ]+$/, "City is invalid")
      .required("Please enter city"),

    phoneNumber: yup
      .string()
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter phone number"),
        otherwise: yup.string(),
      }),

    email: yup
      .string()
      .email("Email is invalid")
      .when([], {
        is: () => conditionalValidate,
        then: yup.string().required("Please enter email"),
        otherwise: yup.string(),
      }),

    state: yup.string().required("Please select state"),
  });



export default class CreateEditProjectPortfolio extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  CustomStepIcon = (props: any) => {
    const { active, completed } = props;
    const getBackgroundColor = () => {
      if (completed) {
        return "#34D399";
      } else if (active) {
        return "#0F172A";
      } else {
        return "#94A3B8";
      }
    };

    const getColor = () => {
      return active || completed ? "#fff" : "#94A3B8";
    };

    const styles = {
      color: getColor(),
      backgroundColor: getBackgroundColor(),
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: 24,
      height: 24,
      fontFamily: "'Poppins', sans-serif",
    };

    return (
      <div style={styles}>
        {completed ? (
          <Check style={{ color: "#fff", fontSize: 20 }} />
        ) : (
          <StepIcon {...props} />
        )}
      </div>
    );
  };

  render() {
    const steps = this.getSteps();
    const {
      activeStep,
      completed,
      formValues: {
        firstName,
        projectName,
        projectDescription,
        lastName,
        postCode,
        city,
        email,
        streetName,
        phoneNumber,
        country,
        state,
        countryCode,
      },
      planId
    } = this.state;
    return (
      <Wrapper>
        <Loader loading={this.state.loading} />
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <Formik
            initialValues={this.state.formValues}
            validationSchema={formSchema(planId != '3')}
            onSubmit={this.submitData}
            enableReinitialize
            validateOnMount={true}
            data-test-id="formik"
          >
            {({
              handleSubmit,
              errors,
              setFieldTouched,
              setFieldValue,
              touched,
              isValid
            }) => (
              <>
                <Box
                  style={{
                    cursor: "pointer",
                    fontFamily: "Poppins, sans-serif",
                    display: "flex",
                    backgroundColor: "#F0F5F7",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginInline: "25px",
                    marginTop: '20px'
                  }}
                >
                  <Typography
                    data-test-id="backButon"
                    onClick={() => this.goBack()}
                    style={{
                      fontFamily: "'Poppins', sans-serif",
                      display: "flex",
                      alignItems: "baseline",
                      width: "15%",
                    }}
                  >
                    <ArrowBackIos style={{ height: "12px" }} />
                    Back
                  </Typography>
                  {planId !=='3' && <Root>
                    <Stepper
                      nonLinear
                      activeStep={activeStep}
                      style={{
                        backgroundColor: "#F0F5F7",
                        fontFamily: "'Poppins', sans-serif",
                        paddingBlock: 0
                      }}
                    >
                      {steps.map((placeholder, index) => (
                        <Step key={placeholder}>
                          <ActiveStepLabel
                            StepIconComponent={(props) => (
                              <this.CustomStepIcon
                                {...props}
                                active={activeStep === index}
                                completed={!!completed[index]}
                              />
                            )}
                          >
                            {activeStep === index && placeholder}
                          </ActiveStepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Root>}
                  <Box style={{ display: "flex" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginInline: "10px",
                      }}
                    >
                      <img height="20" src={addMember} />
                      <Typography
                        style={{
                          color: "#237182",
                          fontSize: "16px",
                          fontWeight: 600,
                          fontFamily: "'Poppins', sans-serif",
                          paddingInline: "5px",
                        }}
                      >
                        Add Member
                      </Typography>
                    </Box>
                    <SaveButton variant="contained">Save Draft</SaveButton>
                    {isValid ?
                    <EnabledNextButton
                      data-test-id="btnSubmitProjectData"
                      type="submit"
                      onClick={() => handleSubmit()}
                    >
                      Next
                    </EnabledNextButton>
                    :
                    <DisabledNextButton
                      data-test-id="btnSubmitProjectData"
                    >
                      Next
                    </DisabledNextButton>
                    }
                  </Box>
                </Box>
                <AppBar
                  position="static"
                  style={{
                    backgroundColor: "#F0F5F7",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                ></AppBar>
                <FormContainer>
                  <Section>
                    <SectionLabel>Project Information</SectionLabel>
                    <InputLabel>Project Name *</InputLabel>
                    <TextField
                      error={this.errorDisplay(errors, touched, "projectName")}
                      value={projectName}
                      variant="outlined"
                      name="projectName"
                      data-test-id="projectName"
                      onChange={(e) => this.handleInputChange(e, setFieldValue)}
                      onBlur={() => setFieldTouched("projectName")}
                      fullWidth
                      placeholder="Enter Name"
                      required
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    {this.errorText(errors, touched, "projectName")}
                  </Section>
                  <Divider />
                  {planId !=='3' && <><Section>
                    <SectionLabel>Client Details</SectionLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Name *</InputLabel>
                        <TextField
                          error={this.errorDisplay(
                            errors,
                            touched,
                            "firstName"
                          )}
                          value={firstName}
                          variant="outlined"
                          name="firstName"
                          data-test-id="firstName"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("firstName")}
                          fullWidth
                          placeholder="Enter Name"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "firstName")}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputLabel>Last name *</InputLabel>
                        <TextField
                          error={this.errorDisplay(errors, touched, "lastName")}
                          value={lastName}
                          name="lastName"
                          data-test-id={"lastName"}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("lastName")}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter Last Name"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "lastName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Email *</InputLabel>
                        <TextField
                          error={this.errorDisplay(errors, touched, "email")}
                          value={email}
                          name="email"
                          variant="outlined"
                          data-test-id="emailTest"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("email")}
                          fullWidth
                          placeholder="Enter Email"
                          type="email"
                          required={planId != '3'}
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "email")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Phone number *</InputLabel>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          <Select
                            style={{
                              width: "20%",
                              height: "55px",
                              fontFamily: "'Poppins', sans-serif",
                            }}
                            data-test-id="inputCountryCode"
                            variant="outlined"
                            name="countryCode"
                            value={countryCode}
                            onChange={this.updateCountryCode}
                            onBlur={() => setFieldTouched("countryCode")}
                            displayEmpty
                            inputProps={{
                              style: {
                                fontFamily: "'Poppins', sans-serif",
                              },
                            }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                              PaperProps: {
                                style: {
                                  height: "323px",
                                  width: "20px",
                                  fontFamily: "'Poppins', sans-serif",   
                                },
                              },
                            }}
                          >
                            {configJSON.countryCodesArray.map(
                              (country: {
                                dial_code: {} | null | undefined;
                                code: React.Key | null | undefined;
                              }) => (
                                <MenuItem
                                  value={country.dial_code as string}
                                  key={country.code}
                                >
                                  <Box display="flex" alignItems="center">
                                    <WorldFlag
                                      code={country.code as string}
                                      height="18"
                                      width="24"
                                      style={{
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                        borderRadius: "1px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                    <Typography
                                      style={{
                                        fontFamily: "'Poppins', sans-serif",
                                      }}
                                    >
                                      &nbsp;{country.dial_code}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              )
                            )}
                          </Select>
                          <TextField
                            error={this.errorDisplay(
                              errors,
                              touched,
                              "phoneNumber"
                            )}
                            data-test-id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*$/.test(value))
                                this.handleInputChange(e, setFieldValue);
                            }}
                            onBlur={() => setFieldTouched("phoneNumber")}
                            name="phoneNumber"
                            style={{
                              fontFamily: "'Poppins', sans-serif",
                              width: "78%",
                            }}
                            variant="outlined"
                            fullWidth
                            placeholder="Enter Phone Number"
                            required={planId != '3'}
                            inputProps={{
                              maxLength: 10,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              style: {
                                fontFamily: "'Poppins', sans-serif",
                              }
                            }}
                          />
                        </Box>
                        {this.errorText(errors, touched, "phoneNumber")}
                      </Grid>
                    </Grid>
                  </Section>
                  <Divider style={{ margin: "10px 0" }} /></>}
                  <Section>
                    <SectionLabel>Address</SectionLabel>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InputLabel>Street Name *</InputLabel>
                        <TextField
                          error={this.errorDisplay(
                            errors,
                            touched,
                            "streetName"
                          )}
                          value={streetName}
                          variant="outlined"
                          name="streetName"
                          data-test-id="streetName"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("streetName")}
                          fullWidth
                          placeholder="Enter Street Name"
                          required
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "streetName")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>City *</InputLabel>
                        <TextField
                          error={this.errorDisplay(errors, touched, "city")}
                          value={city}
                          name="city"
                          variant="outlined"
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("city")}
                          fullWidth
                          placeholder="Enter City"
                          required
                          data-test-id="city-input"
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "city")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Post code *</InputLabel>
                        <TextField
                          data-test-id="postCode"
                          error={this.errorDisplay(errors, touched, "postCode")}
                          variant="outlined"
                          name="postCode"
                          value={postCode}
                          onChange={(e) =>
                            this.handleInputChange(e, setFieldValue)
                          }
                          onBlur={() => setFieldTouched("postCode")}
                          fullWidth
                          placeholder="Enter Post Code"
                          required
                          inputProps={{
                            style: {
                              fontFamily: "'Poppins', sans-serif",
                            },
                          }}
                        />
                        {this.errorText(errors, touched, "postCode")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                          <InputLabel>State *</InputLabel>
                          <Select
                            data-test-id="stateinput"
                            name="state"
                            value={state}
                            onChange={(e) =>
                              this.handleInputChange(e, setFieldValue)
                            }
                            onBlur={() => setFieldTouched("state")}
                            error={this.errorDisplay(errors, touched, "state")}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select State
                              </Typography>
                            </MenuItem>
                            {this.state.stateList.map((state, index) => (
                              <MenuItem
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                                key={index}
                                value={state.isoCode}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.errorText(errors, touched, "state")}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth required variant="outlined">
                          <InputLabel>Country *</InputLabel>
                          <Select
                            data-test-id="countryName"
                            name="country"
                            value={country}
                            onChange={(e) =>
                              this.handleInputChange(e, setFieldValue)
                            }
                            onBlur={() => setFieldTouched("country")}
                            error={this.errorDisplay(
                              errors,
                              touched,
                              "country"
                            )}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <Typography
                                style={{
                                  color: "#1E293B",
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                Select Country
                              </Typography>
                            </MenuItem>
                            {this.state.countryList.map((country, index) => (
                              <MenuItem 
                                key={index} 
                                value={country.isoCode} 
                                style={{
                                  fontFamily: "'Poppins', sans-serif",
                                }}
                              >
                                {country.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.errorText(errors, touched, "country")}
                      </Grid>
                    </Grid>
                  </Section>
                  <Divider style={{ margin: "10px 0" }} />
                  <Section>
                    <SectionLabel>Additional Information</SectionLabel>
                    <InputLabel>Description</InputLabel>
                    <TextField
                      data-test-id="projectDescription"
                      value={projectDescription}
                      onChange={(e) => this.handleInputChange(e, setFieldValue)}
                      onBlur={() => setFieldTouched("projectDescription")}
                      name="projectDescription"
                      variant="outlined"
                      fullWidth
                      placeholder="Write something..."
                      multiline
                      error={this.errorDisplay(
                        errors,
                        touched,
                        "projectDescription"
                      )}
                      rows={4}
                      inputProps={{
                        style: {
                          fontFamily: "'Poppins', sans-serif",
                        },
                      }}
                    />
                    {this.errorText(errors, touched, "projectDescription")}
                  </Section>
                </FormContainer>
              </>
            )}
          </Formik>
        </MyProfileHeader>
      </Wrapper>
    );
  }
}

// Customizable Area End
