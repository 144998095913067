import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// import axios from 'axios';
import { logoutUser, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
interface ErrorResponse {
  errors: ErrorDetail[];
}

interface ErrorDetail {
  failed_login?: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  validations: {
    emailValid: boolean,
    passwordsMatch: boolean,
  
  },
  errors: {
    email: string,
    password: string,
 
  },
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  showPopup: boolean;
  emailErrorFail:string;
  passErrorFail:string;
  originalPassword:string;
  showPassword:boolean;
  asterisk:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallIdData: string = "";
  apiEmailLoginCallId: string = "";
  apiEmailLoginCallIds: string = "";

  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      validations: {
        emailValid: false,
        passwordsMatch: false
      },
      errors: {
        email: '',
        password: '',
      },
      email: "",
      password: "",
      originalPassword:"",
      asterisk:"",
      showPassword:true,
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showPopup: false,
      emailErrorFail:"",
      passErrorFail:"",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const redirectedFrom = await getStorageData("resetOTP");
    if(redirectedFrom){
      this.setState({showPopup: true})
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendLoginFailErrorMessages=(responseJson:ErrorResponse)=>{
    if( responseJson.errors[0].failed_login === "Incorrect password."){
      this.setState({
        passErrorFail: responseJson.errors[0].failed_login,
        emailErrorFail:""
      })
    }else{
      this.setState({
        emailErrorFail: responseJson.errors[0].failed_login || "",
        passErrorFail:""
      })
    }
  }
  handleClose = () => {
    this.setState({ showPopup: false}, async()=>{
      logoutUser();
      await removeStorageData("resetOTP");
    });
  }
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };
  validateEmail = (email: string) => {
    const emailValid = configJSON.emailValidText.test(email);
    const error = emailValid ? '' : 'Please enter valid email address.';

    this.setState(
      {
        validations: { ...this.state.validations, emailValid },
        errors: { ...this.state.errors, email: error },
      },
    );
  };

  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword}));
  }

  handlePasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionStart, selectionEnd } = e.target;
    const { originalPassword } = this.state;
    const inputEvent = e.nativeEvent as InputEvent;
    const isAllTextSelected = selectionStart === 0 && selectionEnd === value.length;
    if (!inputEvent || !inputEvent.inputType) {
      console.error("inputEvent or inputType is undefined");
      return;
    }
    let origi = "xczc";
    if (inputEvent.inputType === "deleteContentBackward" || inputEvent.inputType === "deleteContentForward") {
      if (isAllTextSelected) {
        origi = ""; 
      } else if (inputEvent.inputType === "deleteContentBackward") {
        origi = originalPassword.slice(0, originalPassword.length - 1);
      }else if (inputEvent.inputType === "deleteContentForward") {
        origi = originalPassword.slice(0, originalPassword.length - 1);
      }
    } else {
      origi = originalPassword + value[value.length - 1];
    }
    this.setState({
      originalPassword: origi,
      asterisk: "*".repeat(origi.length),
      password: value,
    }, () => { this.validatePassword(this.state.originalPassword); });
  };
  
  validatePassword = (password: string) => {
    const passwordsMatch = password.length > 0;
    const error = passwordsMatch ? '' : 'Please enter the password';
    this.setState({
      validations: { ...this.state.validations, passwordsMatch },
      errors: { ...this.state.errors, password: error },});
  };
  
  
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    const fieldValue1 = type === 'checkbox' ? checked : value;

    this.setState({ [name]: fieldValue1 } as unknown as Pick<S, keyof S>, () => {
      
      switch (name) {
        
        case 'email':
          this.validateEmail(fieldValue1 as string);
          break;
       
        default:
          break;
      }
    });
  };
  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling

  setRememberMe = (va: boolean) => {
    this.setState({ checkedRememberMe: !this.state.checkedRememberMe });
  };
  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };
  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };
  sendLoginFailMessages(err:any) {
    if(err[0].failed_login=="No account found with this email address."){
      const emailValid = false;
      const error = 'No account found with this email address.';
      this.setState(
        {
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error,password:"" },
        },
      );
    }
  }
  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;
    
  navigateToForgotPassword =async()=>{
    const emailValid = configJSON.emailValidText.test(this.state.email);
    let error = '';
    if (!this.state.email) {
      error = 'Please enter email';
    } else if (!emailValid) {
      error = 'Invalid email address';
    }
    if(!this.state.email || !emailValid){
      this.setState(
        {
          validations: { ...this.state.validations, emailValid },
          errors: { ...this.state.errors, email: error },
        },
      );
      return;
    }
    await setStorageData('emailId', this.state.email)
    this.goToForgotPassword();
  }
  handleSignupNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountRegistration'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }

  handleEmailLoginCallResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.meta && responseJson.data) {
      const id = responseJson.data.id;
      const token = responseJson.meta.token;

      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }

      if (localStorage.getItem("id")) {
        localStorage.removeItem("id");
      }

      localStorage.setItem("token", token);
      localStorage.setItem("id", id);

      const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'CustomisablePersonalProfiles'
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessages(responseJson.errors);
      this.sendLoginFailErrorMessages(responseJson);
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }
  doEmailLogInn(): boolean {if (this.state.email === null || this.state.email.length === 0 || !this.emailReg.test(this.state.email)){
    this.showAlert("Error", configJSON.errorEmailNotValid);
    return false;}
    if (this.state.password === null || this.state.password.length === 0){
    this.showAlert("Error", configJSON.errorPasswordNotValid);
    return false;}
    const header = {"Content-Type": configJSON.loginApiContentType};
    const attrs = {email: this.state.email, password: this.state.originalPassword,};
    const data = {type: "email_account", attributes: attrs };
    const httpBody = {data: data };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiEmailLoginCallIdData = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.loginAPiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody));
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.loginAPiMethod);
    runEngine.sendMessage(requestMessage.id, 
    requestMessage);
    return true;}
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const messageId = message.id;
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
      }  else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallIdData) {
        this.handleEmailLoginCallResponse(responseJson, errorResponse);
    }
      }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
