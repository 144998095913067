import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { crossSigin,success } from "../src/assets";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import MessageBox from "../../../components/src/messageBox";
import { ChangeEvent, CSSProperties } from "react";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  [x: string]: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MyProfileHeader   navigation={this.props.navigation} showSidebar={true}>
        {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status}/>}
        <style>
          {`
            Grid{
              border:2px solid red;
            }
          `}
        </style>
        <Box component="div" style={passwordStylesheet.PasswordBox}>
          <Button
            data-test-id="back-page"
            style={passwordStylesheet.BackBtn as CSSProperties}
            onClick={this.backBtn}
          >
            <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp; Back
          </Button>
          <Box style={{ padding: "30px 32px", backgroundColor: "white" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h2 style={{ margin: "0px", fontSize: "18px", color: "#0F172A" }}>Change Password</h2>
                <p style={{ margin: "0px", fontSize: "14px", color: "#475569" }}>Please fill in below details to change account password.</p>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <Button
                  data-test-id="resetAllPasswordField"
                  onClick={this.resetAllPasswordField}
                  style={passwordStylesheet.cancelBtn as CSSProperties}>
                  Cancel
                </Button>
                <Button
                  data-test-id="save-changes-button"
                  style={this.desiableCheck() ? passwordStylesheet.saveChanges as CSSProperties : passwordStylesheet.saveChangesDisabled as CSSProperties}
                  disabled={!this.desiableCheck()}
                  onClick={this.savePassword}
                >
                  Save Changes
                </Button>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Grid container style={passwordStylesheet.rowReverse as CSSProperties}>
                  <Grid item xs={12} md={6}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                      <Box style={{position:"relative"}}>
                        <label style={passwordStylesheet.inputLabel}> Current Password </label>
                        <input type="text" name="currentPassword" 
                          style={passwordStylesheet.InputText}
                          value={this.state.showCurrentPassword ? this.state.asteriskCurrent : this.state.originalCurrentPassword}
                          data-test-id="handleCurrentPasswordChange"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleCurrentPasswordChange(e)}
                          placeholder="Enter your current password"
                        />
                         {this.state.showCurrentPassword ? (
                          <VisibilityOffOutlinedIcon
                            data-test-id="IconShowCurrentPassword"
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleCurrentVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            data-test-id="IconHideCurrentPassword"
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleCurrentVisibility}
                          />
                        )}
                      </Box>
                      <Box style={{position:"relative"}}>
                        <label style={passwordStylesheet.inputLabel}>New Password</label>
                        <input type="text" name="newPassword"
                          style={passwordStylesheet.InputText}
                          value={this.state.showPassword ? this.state.asterisk : this.state.originalPassword}
                          data-test-id="handleNewPasswordChanged"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleNewPasswordChanged(e)}
                          placeholder="Enter your new password"
                        />
                        {this.state.showPassword ? (
                          <VisibilityOffOutlinedIcon
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleVisibility}
                          />
                        )}
                      </Box>
                      <Box style={{position:"relative"}}>
                        <label style={passwordStylesheet.inputLabel}>Confirm New Password</label>
                        <input
                          type="text"
                          name="confirmPassword"
                          style={{...passwordStylesheet.InputText , border:`${this.errorCheck() ? `1px solid #F87171`:`1px solid #CBD5E1`}`}}
                          value={this.state.showConfirmPassword ? this.state.asteriskConfirm : this.state.originalConfirmPassword}
                          data-test-id="handleConfirmPasswordChange"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleConfirmPasswordChange(e)}
                          placeholder="Confirm your new password"
                        />
                        {this.state.showConfirmPassword ? (
                          <VisibilityOffOutlinedIcon
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleConfirmVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            style={passwordStylesheet.EyePassward as CSSProperties}
                            onClick={this.toggleConfirmVisibility}
                          />
                        )}
                        <p style={{ color: "#DC2626", margin: "0px" }}>{this.errorCheck() && "Password don’t match."}</p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} style={passwordStylesheet.validation as CSSProperties}>
                    <Box style={{ display: "flex", justifyContent: "right" }}>
                      <Box style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                        <p style={{ fontWeight: 600, margin: 0 }}>Your new password must contain</p>
                        <Box>{this.state.requirements.upperLower ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one capital letter and lowercase letter</p></Box>
                        <Box>{this.state.requirements.specialChar ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one special character</p></Box>
                        <Box>{this.state.requirements.number ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one number</p></Box>
                        <Box>{this.state.requirements.length ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>Minimum character length is 8 characters</p></Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </MyProfileHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const passwordStylesheet = {
  PasswordBox: {
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins"
  },
  EyePassward:{
    cursor: 'pointer',
    color: '#94A3B8',
    position: "absolute",
    right: "18px",
    top: "45px"
  },
  inputLabel: {
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    color: "#64748B"
  },
  InputText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Poppins",
    outline: "none",
    backgroundColor:"white"
  },
  cancelBtn: {
    color: "#237182",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    padding:"10px 16px"
  },
  saveChanges: {
    color: "white",
    fontWeight: 600,
    backgroundColor: "#237182",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    marginLeft: "24px"
  },
  BackBtn: {
    color: '#334155',
    fontFamily: "Poppins",
    padding:"10px 16px",
    marginBottom: "24px",
    textTransform: "capitalize",
    fontSize:"14px",
    fontWeight: 600
  },
  saveChangesDisabled: {
    color: '#64748B',
    backgroundColor: '#F1F5F9',
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    marginLeft: "24px",
    padding:"10px 16px"
  },
  rowReverse: {
    ...(window.innerWidth < 600 && {
      display: "flex",
      flexDirection: "column-reverse",
      gap: "25px"
    })
  },
  validation: {
    ...(window.innerWidth < 600 && {
      display: "flex",
      justifyContent: "left",
      alignContent: "center"
    })
  },
}
// Customizable Area End
