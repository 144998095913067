// Customisable Area Start
import React from "react";
import clsx from "clsx";
import CreateEditProjectPortfolioController, {
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  Box,
  Button,
  createTheme,
  Grid,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos, PersonAddOutlined } from "@material-ui/icons";
import { buildNewIcon } from "./assets";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem",
  textAlign: "center",
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "600",
    zIndex: 1,
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
    position: "absolute",
    width: "100%",
    left: 0,
  },
  "& .buttonsBlock": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  "& .addMemberBtn": {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
    zIndex: 1,
  },
  "& .addMemberIcon": {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  "& .addMemberText": {
    fontWeight: 600,
    color: "#237182",
  },
  "& .saveDraftBtn": {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none",
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
    lineHeight: "1.5rem",
    marginBottom: "2rem",
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .typeItem": {
    padding: "2rem 1.5rem",
    background: "#FFF",
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    height: "calc(100% - 4rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .typeTitle": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      color: "#1E293B",
      margin: "1.5rem 0 1rem",
    },
    "& .typeDesc": {
      color: "#475569",
      marginBottom: "1rem",
    },
  },
  "& .selectedTypeItem": {
    border: "1px solid #237182",
  },
  "& .selectTypeBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "none",
      color: "#64748B",
    },
  },
});
const types = [
  {
    id: 1,
    icon: buildNewIcon,
    title: "Residential",
    description: "Textlabel textlabel textlabel",
  },
  {
    id: 2,
    icon: buildNewIcon,
    title: "Commercial",
    description: "Textlabel textlabel textlabel",
  },
];
// Customizable Area End

export default class ProjectType extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <StyledBox>
            <Box className="pageTitle">
              <Box className="backButton" onClick={this.goBack}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
              <Typography className="titleText">Property Type</Typography>
              <Box className="buttonsBlock">
                <Box className="addMemberBtn">
                  <PersonAddOutlined className="addMemberIcon" />
                  <Typography className="addMemberText">Add Member</Typography>
                </Box>
                <Button className="saveDraftBtn">Save Draft</Button>
                <Button
                  className="selectTypeBtn"
                  style={{ width: "auto" }}
                  disabled={this.state.selectedType === 0}
                  data-test-id="next-btn"
                  onClick={this.navigateToScopes}
                >
                  Next
                </Button>
              </Box>
            </Box>
            <Typography className="subTitle">
              You can select only one
            </Typography>
            <Grid container spacing={2} xs={12}>
              {types.map((type) => (
                <Grid item key={type.id} sm={6} xs={12}>
                  <Box
                    className={clsx(
                      "typeItem",
                      this.state.selectedType === type.id && "selectedTypeItem"
                    )}
                    onClick={() => this.handleTypeClick(type.id)}
                    data-test-id={`type_${type.id}`}
                  >
                    <Box>
                      <img src={type.icon} />
                      <Typography className="typeTitle">
                        {type.title}
                      </Typography>
                      <Typography className="typeDesc">
                        {type.description}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </StyledBox>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}
// Customisable Area End
