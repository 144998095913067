import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React, { Component, ChangeEvent } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  name:string
  currentPassword:string;
  newPassword: string;
  confirmPassword: string;
  requirements: {
    length: boolean;
    upperLower: boolean;
    number: boolean;
    specialChar: boolean;
  };
  triggerConfirmPasswordMsg:boolean;
  showCurrentPassword:boolean;
  showPassword:boolean;
  showConfirmPassword:boolean;
  originalCurrentPassword:string;
  originalPassword:string;
  originalConfirmPassword:string;
  asteriskCurrent:string;
  asterisk:string;
  asteriskConfirm:string;
  messageToggle:boolean;
  tostifymessage:string;
  status:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updatePasswordApiId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPassword: "",
      newPassword: "",
      name:"",
      confirmPassword: "",
      requirements: {
        length: false,
        upperLower: false,
        number: false,
        specialChar: false,
      },
      triggerConfirmPasswordMsg:false,
      showCurrentPassword:true,
      showPassword:true,
      showConfirmPassword:true,
      originalCurrentPassword:"",
      originalPassword:"",
      originalConfirmPassword:"",
      asteriskCurrent:"",
      asterisk:"",
      asteriskConfirm:"",
      messageToggle:false,
      tostifymessage:"",
      status:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.updatePasswordApiId === apiRequestCallId) {
        if (responseJson.errors) {
          this.setState({ messageToggle:true, tostifymessage:responseJson.errors[0].password, status:"failed" });  
        } else {
          this.props.navigation.navigate("CustomisableUserProfiles", { messageToggle: true, tostifymessage: 'Password has been changed successfully!', status: "success" });
        }
        this.closeMessageBox();
      }
    }
  
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  validatePassword(password: string) {
    const length = password.length >= 8;
    const upperLower = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    this.setState({
      requirements: {
        length,
        upperLower,
        number,
        specialChar,
      },
    });
  }

  handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { originalCurrentPassword } = this.state;
    let origi = "";
  
    const inputType = (e.nativeEvent as InputEvent).inputType;
  
    if (inputType === "deleteContentBackward") {
      origi = originalCurrentPassword.slice(0, originalCurrentPassword.length - 1);
    } else {
      origi = originalCurrentPassword + value[value.length - 1];
    }
  
    this.setState({
      originalCurrentPassword: origi,
      asteriskCurrent: "*".repeat(origi.length),
      currentPassword: value,
    });
  };

  handleNewPasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { originalPassword } = this.state;
    let origi = "";
  
    const inputType = (e.nativeEvent as InputEvent).inputType;
  
    if (inputType === "deleteContentBackward") {
      origi = originalPassword.slice(0, originalPassword.length - 1);
    } else {
      origi = originalPassword + value[value.length - 1];
    }
  
    this.setState({
      originalPassword: origi,
      asterisk: "*".repeat(origi.length),
      newPassword: value,
    }, () => {
      this.validatePassword(this.state.originalPassword);
    });
  };

  handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { originalConfirmPassword } = this.state;
    let origi = "";
  
    const inputType = (e.nativeEvent as InputEvent).inputType;
  
    if (inputType === "deleteContentBackward") {
      origi = originalConfirmPassword.slice(0, originalConfirmPassword.length - 1);
    } else {
      origi = originalConfirmPassword + value[value.length - 1];
    }
  
    this.setState({
      originalConfirmPassword: origi,
      asteriskConfirm: "*".repeat(origi.length),
      confirmPassword: value,
    });
  
    this.setState({ triggerConfirmPasswordMsg: true });
  };

  desiableCheck(){
    let toggle = Object.values(this.state.requirements).every(Boolean);
    let toggleSec = this.state.originalPassword === this.state.originalConfirmPassword;
    return toggle && toggleSec;
  }
  errorCheck=()=>{
  if(this.state.triggerConfirmPasswordMsg)
   return !(this.state.originalPassword == this.state.originalConfirmPassword)
  }
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword}));
  }

  toggleConfirmVisibility = () => {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword}));
  }

  toggleCurrentVisibility = () => {
    this.setState(prevState => ({
      showCurrentPassword: !prevState.showCurrentPassword}));
  }

  resetAllPasswordField = () => {
   this.setState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    requirements: {
      length: false,
      upperLower: false,
      number: false,
      specialChar: false,
    },
    triggerConfirmPasswordMsg:false,
      showCurrentPassword:true,
      showPassword:true,
      showConfirmPassword:true,
      originalCurrentPassword:"",
      originalPassword:"",
      originalConfirmPassword:"",
      asteriskCurrent:"",
      asterisk:"",
      asteriskConfirm:""
   });
  }

  savePassword = () => {
    // alert(`currentPassword:${this.state.originalCurrentPassword}, newPassword:${this.state.originalPassword}, confirmPassword:${this.state.originalConfirmPassword}`);
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json"
    };

    const payload = {
      "data": {
          "token": token1,
          "current_password": this.state.originalCurrentPassword,
          "new_password": this.state.originalPassword,
          "confirm_password": this.state.originalConfirmPassword
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updatePasswordApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/passwords/change_password`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({messageToggle:false, tostifymessage:"", status:""})
    },3000);
  }

  backBtn = () =>{
    this.props.navigation.navigate("CustomisableUserProfiles");
  }
  // Customizable Area End
}
