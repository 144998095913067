Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.countryCodes = [
    { code: 'CA', name: 'Canada', dial_code: '+1' },
    { code: 'GB', name: 'United Kingdom', dial_code: '+44' },
    { code: 'AU', name: 'Australia', dial_code: '+61' },
    { code: 'DE', name: 'Germany', dial_code: '+49' },
    { code: 'FR', name: 'France', dial_code: '+33' },
    { code: 'IT', name: 'Italy', dial_code: '+39' },
    { code: 'JP', name: 'Japan', dial_code: '+81' },
    { code: 'BR', name: 'Brazil', dial_code: '+55' },
    { code: 'IN', name: 'India', dial_code: '+91' },
    { code: 'CN', name: 'China', dial_code: '+86' },
    { code: 'MX', name: 'Mexico', dial_code: '+52' },
    { code: 'ZA', name: 'South Africa', dial_code: '+27' },
    { code: 'AR', name: 'Argentina', dial_code: '+54' },
    { code: 'RU', name: 'Russia', dial_code: '+7' },
    { code: 'ES', name: 'Spain', dial_code: '+34' },
    { code: 'KR', name: 'South Korea', dial_code: '+82' },
    { code: 'TR', name: 'Turkey', dial_code: '+90' },
    { code: 'EG', name: 'Egypt', dial_code: '+20' },
    { code: 'NG', name: 'Nigeria', dial_code: '+234' },
    { code: 'SA', name: 'Saudi Arabia', dial_code: '+966' },
    { code: 'ID', name: 'Indonesia', dial_code: '+62' },
    { code: 'PK', name: 'Pakistan', dial_code: '+92' },
    { code: 'BD', name: 'Bangladesh', dial_code: '+880' },
    { code: 'TH', name: 'Thailand', dial_code: '+66' },
    { code: 'VN', name: 'Vietnam', dial_code: '+84' },
  ];
// Customizable Area End
