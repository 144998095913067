// Customisable Area Start
import React from "react";
import clsx from "clsx";
import ProjectPlanController, { Props } from "./ProjectPlanController.web";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  Box,
  Button,
  createTheme,
  Grid,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import {
  automatedPlanLogo,
  manualPlanLogo,
  specialistPlanLogo,
} from "./assets";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem",
  textAlign: "center",
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "600",
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
    lineHeight: "1.5rem",
    marginBottom: "2rem",
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .planItem": {
    padding: "2rem 1.5rem",
    background: "#FFF",
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    height: "calc(100% - 4rem)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .planTitle": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      color: "#1E293B",
      margin: "1.5rem 0 1rem",
    },
    "& .planDesc": {
      color: "#475569",
      marginBottom: "1rem",
    },
    "& .planAddText": {
      height: "1.5rem",
      color: "#475569",
      fontWeight: 600,
      marginBottom: "1.5rem",
    },
  },
  "& .selectedPlanItem": {
    border: "1px solid #237182",
  },
  "& .selectPlanBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "none",
      color: "#64748B",
    },
  },
});
const plans = [
  {
    id: 1,
    logo: automatedPlanLogo,
    title: "Automated Cost Plan",
    description:
      "Automatically generate cost estimates based on our comprehensive database. Edit and customize as needed to fit your project requirements.",
  },
  {
    id: 2,
    logo: manualPlanLogo,
    title: "Manual Cost Plan",
    description:
      "Skip the shell stage and directly input room and task details. Manually add tasks and costs for precise budget management.",
  },
  {
    id: 3,
    logo: specialistPlanLogo,
    title: "Specialist Cost Plan",
    description:
      "Get a detailed cost plan from a professional Quantity Surveyor. Upload your documents, and our experts will provide a custom plan for your project.",
    additionalText: "One-time upfront payment of £300.",
  },
];
// Customizable Area End

export default class ProjectPlan extends ProjectPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <StyledBox>
            <Box className="pageTitle">
              <Box
                className="backButton"
                onClick={this.handleBackClick}
                data-test-id="back-btn"
              >
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
              <Typography className="titleText">
                How would you like to start your project?
              </Typography>
              <Button
                className="selectPlanBtn"
                style={{ width: "auto" }}
                disabled={this.state.selectedPlan === 0}
                data-test-id="next-btn"
                onClick={this.handleCreateProjectNavigation}
              >
                Next
              </Button>
            </Box>
            <Typography className="subTitle">
              Select the plan that best suits your project needs.
            </Typography>
            <Grid container spacing={2} xs={12}>
              {plans.map((plan) => (
                <Grid item key={plan.id} sm={6} md={4} xs={12}>
                  <Box
                    className={clsx(
                      "planItem",
                      this.state.selectedPlan === plan.id && "selectedPlanItem"
                    )}
                  >
                    <Box>
                      <img src={plan.logo} />
                      <Typography className="planTitle">
                        {plan.title}
                      </Typography>
                      <Typography className="planDesc">
                        {plan.description}
                      </Typography>
                      <Typography className="planAddText">
                        {plan.additionalText}
                      </Typography>
                    </Box>
                    <Button
                      className="selectPlanBtn"
                      onClick={() => this.handlePlanClick(plan.id)}
                      data-test-id={`plan_${plan.id}`}
                    >
                      Select Plan
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </StyledBox>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}
// Customisable Area End
