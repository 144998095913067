import React from "react";
import { styled, Box, TextField } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { Logo, backgroundImage } from "./assets";
import ToastMessage from './ToastMessage.web';

const Container = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
  fontLine: "24px",
  fontWeight: 600,
  display: "flex",
  height: "100vh",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflowY:"hidden",
  "@media (max-width: 768px)": {
    height:'auto',
    overflowY:'scroll',
    flexDirection:'column'
  },
});

const LeftContainer = styled(Box)({
  flex: 1,
  position: "relative",
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  "@media (max-width: 768px)": {
    padding: '20px'
  },
});

const RightContainer = styled(Box)({
  flex: 1,
  padding: "20px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderBottomLeftRadius: "20px",
  borderTopLeftRadius: "20px",
  backgroundColor: "white",
  "@media (max-width: 768px)": {
    width: "auto",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "block"
  },
});

const BackWrapper = styled("div")({
  cursor: "pointer",
  position: 'fixed',
  left: "51%",
  top: '25px',
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  "& p": {
    fontWeight: 600,
    fontSize: '16px',
    fontFamily: "'Poppins', sans-serif",
    color: '#0F172A',
  },
  "@media (max-width: 768px)":{
    left: 0,
    position:'relative',
    top: 0
  }
});

const Form = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: 0,
  },
});

const InputGroup = styled("div")({
  display: 'flex',
  gap: '10px',
  marginBottom: '5px',
  width:'100%'
});

const DisabledVerifyButton = styled("button")({
  fontFamily: "'Poppins', sans-serif",
  width: "70%",
  padding: "16px",
  border: "none",
  cursor: "pointer",
  color: "#94A3B8",
  background: "#F1F5F9",
  borderRadius: "8px",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: 'none',
});

const EnabledVerifyButton = styled("button")({
  fontFamily: "'Poppins', sans-serif",
  width: "70%",
  padding: "16px",
  border: "none",
  cursor: "pointer",
  color: "#FFFFFF",
  background: "#237182",
  borderRadius: "8px",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: 'none',
});

const ResendLink = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px",
  cursor:'pointer',
  color: "#237182"
});

const DisabledResendLink = styled("div")({
  fontFamily: "'Poppins', sans-serif",
  color: "#94A3B8",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "24px"
});

const LogoContainer = styled("div")({
  position: "absolute",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    position:'inherit'
  },
});

const LogoImage = styled("img")({
  width: "70%",
  height:'70%',
  "@media (max-width: 1440px)": {
    width: "79%",
    height:'79%'
  },
});

const LogoTextTop = styled("div")({
  color: "#fff",
  fontSize: "30px",
  fontWeight: 700,
  width: "100%",
  fontFamily:"'Poppins', sans-serif",
  letterSpacing: '-0.5px'
});

const LogoText = styled("div")({
  textAlign: "center",
  font: "poppins",
  fontWeight: 700,
  fontSize: "48px",
  color: "white",
  lineHeight: "1.2",
  letterSpacing: '-3px',
  fontFamily:"'Poppins', sans-serif",
  "@media (max-width: 768px)": {
    fontSize: "24px",
  },
});

const LogoText1 = styled("div")({
  textAlign: "center",
  fontFamily: "'Poppins', sans-serif",
  fontWeight: 400,
  fontSize: "18px",
  color: "white",
});

const TextContainer = styled("div")({
  paddingLeft: "11px",
  paddingRight: "12px",
});


const OTPInput = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    height: '100%',
    "& fieldset": {
      borderColor: "#CBD5E1",
    },
    "&:hover fieldset": {
      border: "0.5px solid lightgrey",
      boxShadow: "0 0 10px #C5E0E7",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid grey",
      boxShadow: "0 0 10px #C5E0E7",
    },
  },
  "& input": {
    textAlign: 'center',
  },
  "& .Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#DC2626",
  },
}));

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordOTPWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container style={{
        backgroundImage: `url(${backgroundImage})`
      }}>
        <LeftContainer>
          <LogoContainer>
            <LogoTextTop>Logo Here</LogoTextTop>
            <LogoImage src={Logo} alt="Logo" />
            <TextContainer>
              <LogoText>Your new construction management tool.
              </LogoText>
              <LogoText1> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque</LogoText1>
            </TextContainer>
          </LogoContainer>
        </LeftContainer>
        <RightContainer>
          <BackWrapper data-test-id='backIcon' onClick={() => this.goBackToForgotPassword()}>
            <ChevronLeft fill="#0F172A" />
            <p>&nbsp;&nbsp;Back</p>
          </BackWrapper>
          <Form data-test-id="loginForm">
            <div data-test-id="btnSocialLogin"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: "#0F172A",
                fontSize: "30px",
                paddingTop: "20px",
                fontFamily: "'Poppins', sans-serif",
              }}
            >Verification Code</div>
            <p style={{
              fontSize: '16px',
              fontWeight: 400,
              color: '#475569',
              fontFamily: "'Poppins', sans-serif",
              textAlign: "center",
              marginBlock: '20px',
            }}>Please <span style={{ color: '#475569', fontWeight: 600 }}>enter the 6-digit code</span> sent to your email address</p>
            <div style={{ width:"70%", display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:'20px' }}>
              <InputGroup data-test-id="otpInputs">
                {this.state.otp && this.state.otp.map((data, index) => (
                  <OTPInput
                    key={index}
                    type="text"
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                    value={data}
                    onChange={e => this.handleChange(e, index)}
                    onKeyDown={(e) => this.handleKeyDown(e as React.KeyboardEvent<HTMLInputElement>, index)}
                    inputRef={this.inputs[index]}
                    variant="outlined"
                    style={{  height: '74px' }}
                    error={this.state.invalidOTP}
                    data-testid={`otp-input-${index}`}
                    disabled={this.state.timer === 0}
                  />
                ))}
              </InputGroup>
              {this.state.invalidOTP && <span style={{fontWeight:400, width:'100%', color: '#DC2626', fontSize: '14px', marginTop: '4px' }}>Invalid Code</span>}
            </div>
            <div style={{ display: "flex", width: '70%', justifyContent: 'space-between', marginBottom: "15px" }}>
              {!this.state.timer ?
              <ResendLink data-test-id="resendLink" onClick={()=>this.sendOTP()}>Resend Code?</ResendLink>
            :
              <DisabledResendLink data-test-id="resendLink">Resend Code?</DisabledResendLink>
            }
              <span data-test-id="timer" style={{ fontSize: '18px', fontWeight: 700, fontFamily: "'Poppins', sans-serif", color: !this.state.timer ? "#DC2626" : '#237182' }}>
                {this.state.timer !== undefined ? `0.${this.state.timer.toString().padStart(2, '0')}` : ''}
              </span>
            </div>
            {this.state.enableVerifyBtn
              ?
              <EnabledVerifyButton data-test-id="btnVerify" onClick={() => this.verifyOTP()}>Verify Code</EnabledVerifyButton>
              :
              <DisabledVerifyButton disabled data-test-id="btnVerify">Verify Code</DisabledVerifyButton>
            }
          </Form>
          <ToastMessage
            open={this.state.showNotification}
            message={this.state.notificationMessage}
            type={this.state.notificationType}
            onClose={this.handleClose}
          />
        </RightContainer>
      </Container>
    );
  }
}
