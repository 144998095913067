import React from "react";
// Customizable Area Start
import {
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { 
  createTheme, 
  ThemeProvider
 } from "@material-ui/core/styles";
const loginbg = require("../assets/loginbg.jpg");
import { leftBackground} from "./assets";
import WorldFlag from 'react-world-flags';
import "react-datepicker/dist/react-datepicker.css";

const webStyles = { 
  mainbox: {
    display: 'flex',
    height: '100vh',
    padding: 0,
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
  },
  rightBox: {
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    padding: "48px",
    height: "100vh",
    width: "50%",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    overflowY: "auto",
  } as const,
  leftbox: {
    padding: "48px",
    height: "100vh",
    width: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
  } as const,
  logohereText: {
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: 'left',
  } as const,
  imgPic: {
    maxWidth: "500px",
    height: "50%",
    width: "100%",
  },
  msgText: {
    fontWeight: "700",
    lineHeight: "1.2",
    color: "white",
    fontSize: "48px",
    fontFamily:"Poppins",
    marginTop:"35px"
  },
  smallText: {
    fontSize: "18px",
    color: "white",
    width:"70%",
    fontFamily: "'Poppins'",
    fontWeight: 400,
    marginTop:"9px",
    textAlign: "center",
  }as const,
  labelContainer: {
    marginRight: "20px",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color:"#334155",
  },
  label: {
    verticalAlign: "middle",
    color: "#237182",
    cursor: 'pointer',
    gap: "5px",
    display: "inline-block",
    fontFamily: "Poppins"
  },
  boldText: {
    color: "rgba(35, 113, 130, 1)",
    fontWeight: "bold",
    verticalAlign: "middle",
    display: "inline-block",
    marginLeft: "5px",
  },
  labelText: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#334155',
    textAlign: 'center',
  }as const,
  labelBrowse: {
    display: "inline-block",
  } as const,
  inlineIcon: {
    verticalAlign: "middle",
    display: "inline-block",
    color: "#237182"
  },
  fileUploadContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  fileInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  fileLogo: {
    width: '50px',
    height: '50px',
  }, 
};
const errorMessageStyles = {
  color: 'red',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '0.5rem',
  marginTop: '4px',
  fontFamily: "'Poppins'",
};
const styles = {
  groupSection1: {
    display: "flex",
    alignItems: "center",
    width:"100%",
    marginTop:"160px"
  },
  group1: {
    
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    alignSelf: "stretch",
    width:"100%",
    gap: "32px",
   
  } as const,
  groupTop: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
  } as const,
  
  group2: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "stretch",
    flexDirection: "column"
  } as const,

  group3: {
   
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width:"100%",
    padding: "0 7px",
    fontSize: "16px", 
  } as const,
  titleLabel: {
    letterSpacing: "normal",
    fontFamily: "'Poppins",
    position: "relative",
    fontSize: "30px", 
    lineHeight: "40px",
    fontWeight: "700", 
    textAlign: "center",
    margin: 0,
    alignSelf: "stretch",
    
  } as const,
  groupStep: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "8px 25px",
    boxSizing: "border-box",
    minWidth: "195px",
  } as const,
  groupStep1: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    overflow: "hidden",
  } as const,
  groupActiveStep: {
    height: "32px",
    width: "32px",
    borderRadius: "50%", 
    // backgroundColor: "#6be578",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  } as const,
  imageIcon: {
    // width: "100%", 
    height: "24px",
    borderRadius: "50%", 
    objectFit: "cover", 
  } as const,
  groupStep2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "8px 25px",
    boxSizing: "border-box",
    minWidth: "195px",
    color: "#ffffff",
  } as const,
  groupStep3: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    overflow: "hidden",
  } as const,
  groupActiveStep1: {
    height: "32px",
    width: "32px",
    borderRadius: "90px",
    // backgroundColor: "#1F2937",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 11px",
    boxSizing: "border-box",
  } as const,
 
  groupContent: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "16px",
    textAlign: "left",
    fontSize: "inherit",
    color: "#475569",
    marginTop:"20px"
  } as const,
  textlabelTitle: {
    fontWeight: 600,
    color: "#64748B",
    position: "relative",
    textDecoration: "none",
    lineHeight: "24px",
    fontFamily: "'Poppins'",
    fontSize:"16px",
    
  } as const,
  group43: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    gap: "24px",
    marginTop:"-20px"
  } as const,
  group4: {
    width: "100%",
    display: "flex",
    gap: "24px",
    maxWidth: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop:"9px"
  } as const,

  group9: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
    maxWidth: "100%",
    marginTop:"10x"
  } as const,
  groupInputWithLabel: {
    flex: 1,
    color:"#334155",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    boxSizing: "border-box",
  } as const,
  groupInputWithLabelPN: {
    display: 'flex',
    width: '100%',
    gap:"16px",
    color:"#334155",
  } as const,
  groupPH: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop:"10px",
    flexDirection:"column"
  } as const,
  inputName: {
    lineHeight: "24px",
    fontWeight: 600,
    fontFamily: "Poppins",
    marginBottom:"10px"
    
  } as const,
  inputName1: {
    lineHeight: "24px",
    fontWeight: 600,
    fontFamily: "Poppins",
    visibility: "hidden"
  } as const,
  inputNameTP: {
    // marginBottom: '8px'
    visibility: "hidden"
  } as const,
  inputNamePN: {
    fontFamily: 'Poppins', // Font
    fontWeight: 600, // Weight
    fontSize: '16px', // Size
    lineHeight: '24px', // Line height
    color: '#334155' // Color
  } as const,
  textinputBoxed: {
    height: "56px",
    backgroundColor: "#FFFFF",
    borderRadius: "8px",
    width: "100%",
    color:"#0F172A !important"
  } as const,
  textinputBoxed2: {
    height: "56px",
    backgroundColor: "#FFFFF",
    borderRadius: "8px",
    width: "100%",
  } as const,
  textinputBoxedCountryCode: {
    height: "56px",
    backgroundColor: "#FFFFF",
    borderRadius: "8px",
    width: "100%",
  } as const,
  textinputBoxedCountryCodePN: {
    backgroundColor: "#FFFFF",
    borderRadius: "8px",
  } as const,
  textinputBoxed3: {
    // width: '70%'
    height: "52px",
    backgroundColor: "#FFFFF",
    borderRadius: "8px",
    marginTop:"1px"
  } as const,
  button: {
    flex: 1,
    minWidth: "193px",
    textTransform: "none",
    color: "#64748b",
    fontSize: "16px",
    background: "#E4E7EA",
    borderRadius: "8px",
    fontWeight:"600",
    fontFamily: "Poppins",
    "&:hover": { background: "#f1f5f9" },
  } as const,
  submitButtonActive: {
    background: '#237182',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '100%',
    marginTop:"5rem"
  }as const,
  submitButton: {
    backgroundColor: '#E2E8F0',
    color: '#64748B',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '100%'
  }as const,
};
const inputStyles = {
  borderRadius: "8px",
  color: "#0F172A",
  fontFamily: "'Poppins'",
  
};

// Customizable Area End

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
      },
    },
  },
});

import CustomisableClientProfileController , {
    Props,
    configJSON
  } from  "./CustomisableClientProfileController";
// Customizable Area End

export default class CustomisableClientProfile extends CustomisableClientProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  render() {
    // Customizable Area Start
    const { formComplete, aboutMeErrorMsg,aboutMeError,phoneNumberError,phoneNumberErrorMsg,lastNameError,lastNameErrorMsg,firstNameError,firstNameErrorMsg,countryCode,countryCodeError } = this.state;
    
    // In your component class


  
    return (
      <ThemeProvider theme={theme}>
        {/* <Container maxWidth='md'> */}
          {/* Customizable Area Start */}
          
          <Grid container style={webStyles.mainbox} >
          <Grid item lg={6} sm={12} xs={12} style={{
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              boxSizing: "border-box",
              textAlign: "center",
              padding: "48px",
              height: "100vh",
              width: "50%",
              backgroundImage: `url(${loginbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}>
          <Box>
            <Typography style={webStyles.logohereText}>
              Logo Here
            </Typography>
            <img src={leftBackground} style={webStyles.imgPic} />
            <Box style={{
              alignItems:"center",
              display:"flex",
              flexDirection:"column"
            }} paddingX={5}>
              <Typography style={webStyles.msgText}>
                Your new construction management tool.
              </Typography>
              <Typography style={webStyles.smallText}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} sm={12} xs={12} data-test-id='oKay' style={webStyles.rightBox} >
        <Box style={styles.groupSection1}>
        <Box style={styles.group1}>
            <Box style={styles.groupTop}>
              <Box style={styles.group2}>
                <Typography style={styles.titleLabel}>
                  Create your profile
                </Typography>
              </Box>
            </Box>
            <Box style={styles.groupContent} component="form" >
              <Box style={styles.group43}>
                <Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>
                    First Name *
                  </Typography>
                  <TextField
                    style={styles.textinputBoxed}
                    data-test-id="txtInputFirstName"
                    placeholder="Enter first name"
                    variant="outlined"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.updateFirstName}
                    error={firstNameError}
                    helperText={this.getHelperText(firstNameError, firstNameErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles }} 
                  />

           
                </Box>
                <Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>Last Name *</Typography>
                  <TextField
                    style={styles.textinputBoxed}
                    data-test-id="txtInputLastName"
                    placeholder="Enter last name"
                    variant="outlined"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.updateLastName}
                    error={lastNameError}
                    helperText={this.getHelperText(lastNameError, lastNameErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles }} 
                  />
                
                </Box>
              </Box>
              <Box style={styles.groupPH}>
              <Box style={{ ...styles.groupInputWithLabelPN, width: "100%" }}>
                <Typography style={styles.inputName}>Phone Number *</Typography>
      
              </Box>
              <Box style={{ ...styles.groupInputWithLabelPN, width: "100%" }}>
              <Select
                  style={{ ...styles.textinputBoxedCountryCodePN, width: '127px', height:'55px' }}
                  data-test-id="txtInputCountryCode"
                  variant="outlined"
                  name="countryCode"
                  value={countryCode}
                  onChange={this.updateCountryCode}
                  error={countryCodeError}
                  displayEmpty
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        
                       
                        height:"323px",
                        width:"20px",
                      
                      },
                    },
                  }}
                >
                  {configJSON.countryCodes.map((country: { dial_code: {} | null | undefined; code: React.Key | null | undefined; }) => (
                    <MenuItem value={country.dial_code as string} key={country.code}>
                      <Box display="flex" alignItems="center">
                      <WorldFlag code={country.code as string}  height="15px" width="20px" />
                        <Typography >&nbsp;{country.dial_code}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
           
                <TextField
                fullWidth
                  style={styles.textinputBoxed3}
                  data-test-id="txtInpuPhoneNumber"
                  placeholder="00000 00000"
                  variant="outlined"
                  name="phoneNumber"
                  type="tel"
                  value={this.state.phoneNumber}
                  InputProps={{ style: { ...styles.textinputBoxed3 },  inputProps: {
                    pattern: "[0-9]*",
                    inputMode: "numeric"
                  } }}
                  onKeyPress={(event) => {
                    if (!/\d/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={this.updatePhoneNumber}
                  error={phoneNumberError}
                  helperText={this.getHelperText(phoneNumberError, phoneNumberErrorMsg)}
                  FormHelperTextProps={{ style: errorMessageStyles }} 
                />
                  
              </Box>
            </Box>
              <Box style={styles.group9}>
                <Box style={styles.groupInputWithLabel}>
                  <Typography style={styles.inputName}>
                    About Me *
                  </Typography>
                  <TextField
                    fullWidth
                    style={styles.textinputBoxed2}
                    data-test-id="txtInputAbout"
                    placeholder="Write some thing about yourself..."
                    variant="outlined"
                    name="aboutMe"
                    value={this.state.aboutMe}
                    onChange={this.updateAboutMe}
                    error={aboutMeError}
                    helperText={this.getHelperText(aboutMeError, aboutMeErrorMsg)}
                    InputProps={{ style: inputStyles }}
                    FormHelperTextProps={{ style: errorMessageStyles }} 
                  />
                
                </Box>
              </Box>
            </Box>

            <Box display="flex" gridGap={10} justifyContent="space-between" alignItems="center" width="100%">
              <Button
                data-test-id="submitBtn"
                type="button"
                style={formComplete ? styles.submitButtonActive : styles.submitButton}
                disabled={!formComplete}
                fullWidth
                >
                Next
              </Button>
            </Box>
          </Box>
      </Box>
        </Grid>
          </Grid>

          {/* Customizable End Start */}
        {/* </Container> */}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
