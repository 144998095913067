// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface RowData {
  id: string;
  name: string;
  members: number;
  dataTime: string;
}

interface MembersList {
  id: string | number;                       
  name: string;        
  email: string;               
  teams: string;        
  role: string;        
  status: string;      
}


interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  toggle: boolean;
  checked: boolean;
  rows: RowData[];
  membersList: MembersList[];
  dialogOpen: boolean;
  selectedRow: RowData | null;
  anchorEl: HTMLElement | null;
  loading: boolean;
  token: string;
  teamName: string;
  teamNameError: boolean;
  isRenameModalOpen: boolean;
  selectedRoles: string[];
  selectedTeam: string[];
  activated: string[];
  isRoleOpen: boolean;
  isTeamOpen: boolean;
  isApproveOpen: boolean;
}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTeamsListCallId = "";
  deleteTeamCallId = "";
  editTeamNameCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      rows: [],
      dialogOpen: false,
      selectedRow: null,
      anchorEl: null,
      loading: false,
      token: "",
      teamName: "",
      teamNameError: false,
      isRenameModalOpen: false,
      toggle:true,
      checked:false,
      membersList: [
        {
          id: "1",
          name: "member 1",
          email:'member1@yupmail.com',
          status: "not activated",
          role:'Owner',
          teams: 'Sales Team'
        },
        {
          id: "2",
          name: "member 2",
          email:'member2@yupmail.com',
          status: "not activated",
          role:'Manager',
          teams: 'Marketing team'
        },
        {
          id: "3",
          name: "member 3",
          email:'member3@yupmail.com',
          status: "not activated",
          role:'Employee',
          teams: 'Accounts Team'
        },
      ],
      selectedRoles: [],
      selectedTeam: [],
      activated: [],
      isRoleOpen: false,
      isTeamOpen: false,
      isApproveOpen: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getTeamsListApi();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.errors) {
        if (apiCallId === this.editTeamNameCallId) {
          this.setState({ teamNameError: true });
        } else {
          this.showAlert("Alert", responseJson.errors[0]);
          this.setState({ loading: false });
        }
      }

      if (apiCallId === this.getTeamsListCallId) {
        const formatedData = responseJson.data.map((team: any) => ({
          id: team.id,
          name: team.attributes.team_name,
          members: team.attributes.members_count,
          dataTime: moment(team.attributes.created_at).format("DD MMMM YYYY"),
        }));
        this.setState({
          rows: formatedData,
          loading: false,
        });
      }
      if (
        apiCallId === this.deleteTeamCallId ||
        apiCallId === this.editTeamNameCallId
      ) {
        this.handleRenameModal(false);
        this.handleClose();
        this.getTeamsListApi();
      }
    }
  }

  handleRenameModal = (state?: boolean) => {
    this.setState((prev) => ({
      isRenameModalOpen: state || !prev.isRenameModalOpen,
      teamName: "",
      teamNameError: false,
    }));
  };

  handleTeamNameChange = (teamName: string) => {
    this.setState({ teamName, teamNameError: false });
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgSuccess = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgSuccess.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgSuccess);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };

  getTeamsListApi = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTeamsListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.teamEndPoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  deleteTeamApi = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteTeamCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  editTeamNameApi = () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamName,
        },
      },
    };

    this.editTeamNameCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };
  
  handleToggle=()=>{
    this.setState(prevState=>({
      toggle:!prevState.toggle
    }))
  }

  handleChange = (event: React.ChangeEvent<{ value: unknown, name?: string }>) => {
    const { name, value } = event.target;
    if(name == 'role'){
      this.setState({
        selectedRoles: value as string[],
      });
    } else if(name == 'team'){
      this.setState({
        selectedTeam: value as string[],
      });
    } else if(name == 'activated'){
      this.setState({
        activated: value as string[],
      });
    }
  };

  getRoleStyles(role: string) {
    let backgroundColor;
    switch (role) {
      case 'Owner':
        backgroundColor = '#237182';
        break;
      case 'Employee':
        backgroundColor = '#C5E0E7';
        break;
      default:
        backgroundColor = '#E0EDF0';
    }
    const color = role === 'Manager' ? '#237182' : '#fff';
    return { backgroundColor, color };
  }
}

// Customizable Area End
