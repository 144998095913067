// Customizable Area Start
import React from "react";
import {
  Box, Typography, InputAdornment, Grid, Tabs,Divider, Tab, Switch, TextField, Card, CardContent, CardActions, IconButton, Menu, MenuItem, AppBar, Toolbar,
} from "@material-ui/core";
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { styled } from "@material-ui/styles";
import { MoreVert, List, AddCircleOutline, FolderOpen, EditSharp, ControlPointDuplicateSharp, DeleteOutline, Search } from '@material-ui/icons';

import ProjectPortfolioController, {
  Props
} from "./ProjectPortfolioController";
import Loader from "../../../components/src/Loader.web";
import { IProject } from "./types";
import MyProfileHeader from "../../../components/src/MyProfileHeader";

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const tabs = [
        { label: "All", index: 0 },
        { label: "Ongoing", index: 1 },
        { label: "Draft", index: 2 },
        { label: "Proposed", index: 3 },
        { label: "Completed", index: 4 },
        { label: "Paused", index: 5 },
    ];

    const renderTab = ({ label, index }: { label: string; index: number }) => (
        <Tab
            key={label}
            label={label}
            style={{
                minWidth: "auto",
                paddingInline: '20px',
                textTransform: "none",
                borderRadius: index === 4 ? "8px" : "25px",
                backgroundColor: this.state.activeTab === index ? "#E0EDF0" : "#F8FAFC",
                color: this.state.activeTab === index ? "#0F172A" : "#64748B",
                fontWeight: this.state.activeTab === index ? "bold" : 600,
            }}
        />
    );

    return (
        <Box>
        <MyProfileHeader navigation={this.props.navigation} tabName={'Projects'}>
            <CustomTabs position="static">
                <Toolbar
                    style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        width: "97%",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        marginTop: "10px",
                        borderRadius: "8px",
                    }}
                >
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{backgroundColor: "#F8FAFC", borderRadius: "18px", marginRight: "10px"}}
                    >
                        {tabs.map(renderTab)}
                    </Tabs>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <List style={{ color: '#334155' }} />
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 600, paddingInline: '4px' }}>List View</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px' }}>Sort By</Typography>
                        <CustomDropDown
                            id="outlined-select-currency-native"
                            select
                            value={this.state.sortTypeValue}
                            data-test-id={'sortFilter'}
                            onChange={this.handleSortByChange}
                            SelectProps={{
                                native: true,
                            }}
                        >
                            {this.state.sortType.map((option: { value: string; label: string }) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </CustomDropDown>
                    </Box>
                    <CustomSearchBox data-test-id={'searchInputText'} variant="outlined" id="outlined-basic" placeholder="Search here..." InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ color: '#94A3B8' }} />
                        </InputAdornment>
                      ),
                    }}/>
                </Toolbar>
            </CustomTabs>
            <Grid container spacing={2} style={{ padding: '15px', backgroundColor: '#F0F5F7' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', minHeight: '200px' }}>
                    <Card elevation={0} style={{
                        width: '100%', height: "100%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center"
                    }} data-test-id="btnAddProject">
                        <CardContent style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <AddCircleOutline data-test-id="toCreateProject" onClick={() => this.naviagteToCreatePage()} style={{ borderRadius: "4px", backgroundColor: '#237182', padding: '8px', color: '#fff', cursor: "pointer" }} />
                            <Typography style={{ color: '#475569', fontSize: '16px', fontWeight: 600, marginTop: '20px' }}>Create new project</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {this.state.projects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ display: 'flex', height: '200px' }}>
                        <Card elevation={0} style={{ display: 'flex', width:'100%' }} data-test-id={`projectDetail${index}`}>
                            <CardContent style={{
                                display: "flex",
                                gap: "4px",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                width:'100%'
                            }}>
                                <Typography variant="h6" style={{ overflow: 'hidden', lineHeight: "24px", fontSize: '16px', color: '#0F172A', fontWeight: 600 }}>{project.title}</Typography>
                                <div>
                                    <Typography variant="body2" color="textSecondary" style={{ marginBottom: '3px' }}>{project.location}</Typography>
                                    <Typography variant="h6" style={{ fontSize: '16px', color: '#1E293B', fontWeight: 600 }}>{project.cost}</Typography>
                                </div>
                            </CardContent>
                            <CardActions disableSpacing style={{
                                flexDirection: "column",
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <IconButton onClick={this.handleClick} style={{ padding: '1px' }}>
                                    <MoreVert />
                                </IconButton>
                                <CustomMenu
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                    elevation={0}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={this.handleClose}><EditSharp style={{ height: '18px', marginRight: '4px' }} />Rename</MenuItem>
                                    <MenuItem onClick={this.handleClose}><ControlPointDuplicateSharp style={{ height: '18px', marginRight: '4px' }} />Duplicate</MenuItem>
                                    <MenuItem style={{ color: '#DC2626', alignItems: 'flex-start' }} onClick={this.handleClose}><DeleteOutline style={{ height: '20px', marginRight: '4px' }} />Delete</MenuItem>
                                </CustomMenu>
                                <FolderOpen style={{ backgroundColor: '#E0EDF0', color: '#237182', padding: '10px', borderRadius: '4px' }} />
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
    </MyProfileHeader>
            <Loader loading={this.state.loading}/>
        </Box>
    );
}

}

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #d3d4d5",
    width:"190px",
    height: "121px",  
    alignItems: "center",
    display: "grid"
  },
  "& .MuiList-padding": {
    paddingTop:0,
    paddingBottom:0
  }
});

const CustomSearchBox = styled((TextField))({
  width: '35%',
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: '#CBD5E1',
    },
    "&:hover fieldset": {
      borderColor: '#CBD5E1',
    },
    "&.Mui-focused fieldset": {
      borderColor: '#CBD5E1',
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 0px",
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    }
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(10px, 10px)"
  },
  "& .MuiFormLabel-root": {
    fontSize: '15px',
    color: '#94A3B8'
  }
});

const CustomTabs = styled((AppBar))({
  width: "100%",
  backgroundColor: "#F0F5F7",
  "& .MuiTab-root":{
    minHeight:'auto'
  },
  "& .MuiTabs-root":{
    minHeight:'auto'
  }
})
const CustomDropDown = styled((TextField))({
  "& .MuiInput-underline::before":{
    borderBottom:0,
  },
  "& .MuiInput-underline::after":{
    borderBottom:0,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before":{
    borderBottom:0,
  },
  "& .MuiSelect-select:focus": {
    backgroundColor:'#fff',
    color:'#237182'
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingBlock: "10px",
    color:'#237182',
    fontSize:'16px',
    fontWeight: 600
  }
})

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#325962',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #ffffff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
  
)(Switch);

// Customizable Area End
