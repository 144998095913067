import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  email: string,
  password: string,
  confirmPassword: string,
  agreeToTerms: boolean,
  validations: {
    emailValid: boolean,
    hasUpper: boolean,
    hasLower: boolean,
    hasSpecialChar: boolean,
    hasNumber: boolean,
    minLength: boolean,
    passwordsMatch: boolean,
    termsAccepted: boolean,
  },
  errors: {
    email: string,
    password: string,
    confirmPassword: string,
    terms: string,
    account:string,
    msg:string
  },
  isDisabled: boolean;
  accountError:string; 
  openModal:boolean;
  openTermsCondModal:boolean
  termsdata:Array<object>
  visiblePassword:boolean;
  visibleConfirmPass:boolean;
  originalPassword:string;
  originalPassword1:string;
  event:any;
  asterisk:string;
  asterisk1:string;
  showPassword:boolean
  showPassword1:boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailSignUpApiCallId :string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.receive = this.receive.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: '',
      password: '',
      confirmPassword: '',
      agreeToTerms: false,
      validations: {
        emailValid: false,
        hasUpper: false,
        hasLower: false,
        hasSpecialChar: false,
        hasNumber: false,
        minLength: false,
        passwordsMatch: false,
        termsAccepted: false,
      },
      errors: {
        email: '',
        password: '',
        confirmPassword: '',
        terms: '',
        account: '',
        msg: ''
      },
      isDisabled: false,
      accountError:'',
      openModal:false,
      openTermsCondModal:false,
      termsdata:[], 
      visiblePassword:true,
      visibleConfirmPass:true,
      originalPassword:'',
      originalPassword1:'',
      event:"",      
      asterisk:"",
      asterisk1:"",
      showPassword:false,
      showPassword1:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.emailSignUpApiCallId) {
        if(responseJson.data && responseJson.data.id) {   
       
          const id = responseJson.data.id;
          const token = responseJson.meta.token;
          
          if (localStorage.getItem("id")) {
            localStorage.removeItem("id");
          }
          if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
          }
    
          // Store id and token in local storage
          localStorage.setItem("id", id);
          localStorage.setItem("token", token);
          localStorage.setItem("email",this.state.email)
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          msg.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'Emailnotifications2'
          );
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          )
          this.send(msg);       
          return;
        }
        this.handleErrorSignup(responseJson);
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount () {
  super.componentDidMount();
  document?.addEventListener('keydown', (e) => {
  this.setState({event: e})
  })
 }

  handleErrorSignup=(responseJson:any)=>{
    const emailValid = false;
    this.setState({
      validations: { ...this.state.validations, emailValid },
      errors: { ...this.state.errors, email: responseJson?.errors }
    });
  }

    validateConditions = () => {
      const {
        emailValid,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
        termsAccepted
      } = this.state.validations;
  
      return emailValid && hasUpper && hasLower && hasSpecialChar && hasNumber &&
             minLength && passwordsMatch && termsAccepted &&
             this.state.password === this.state.confirmPassword;
    };
    validateEmail = (email: string) => {
      const emailValid = configJSON.emailValidText.test(email);
      const error = emailValid ? '' : 'Please enter valid  email address.';
    
      this.setState({
        validations: { ...this.state.validations, emailValid },
        errors: { ...this.state.errors, email: error },
      });
    };

    handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, selectionStart, selectionEnd } = e.target;
      const { originalPassword } = this.state;
      const inputEvent = e.nativeEvent as InputEvent;
    
      if (!inputEvent || !inputEvent.inputType) {
        console.error("inputEvent or inputType is undefined");
        return;
      }
    
      const isAllTextSelected = selectionStart === 0 && selectionEnd === value.length;
      
      let origi = "xczc";
      
      if (inputEvent.inputType === "deleteContentBackward" || inputEvent.inputType === "deleteContentForward") {
        if (isAllTextSelected) {
          origi = ""; 
        } else if (inputEvent.inputType === "deleteContentBackward") {
          origi = originalPassword.slice(0, originalPassword.length - 1);
        } else if (inputEvent.inputType === "deleteContentForward") {
          origi = originalPassword.slice(0, originalPassword.length - 1);
        }
      } else if (inputEvent.inputType === "insertText") {
        origi = originalPassword + value[value.length - 1];
      } else {
        origi = value;
      }
    
      this.setState({
        originalPassword: origi,
        asterisk: "*".repeat(origi.length),
        password: value,
      }, () => {
        this.validatePassword(this.state.originalPassword);
      });
    };
    
  validatePassword = (password: string) => {
    const hasUpper = configJSON.hasUpper.test(password);
    const hasLower = configJSON.hasLower.test(password);
    const hasSpecialChar = configJSON.hasSpecialChar.test(password);
    const hasNumber = configJSON.hasNumber.test(password);
    const minLength = password.length >= 8;
    const passwordsMatch = this.state.confirmPassword === '' || password === this.state.confirmPassword;
  
    const passwordError = hasUpper && hasLower && hasSpecialChar && hasNumber && minLength ? '' : 'Password is not valid';
    const confirmPasswordError = this.state.confirmPassword && !passwordsMatch ? "Passwords don't match" : '';
  
    this.setState({
      validations: {
        ...this.state.validations,
        hasUpper,
        hasLower,
        hasSpecialChar,
        hasNumber,
        minLength,
        passwordsMatch,
      },
      errors: {
        ...this.state.errors,
        password: passwordError,
        confirmPassword: confirmPasswordError,
      },
    });
  };
  
  handleChangePassword1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, selectionStart, selectionEnd } = e.target;
    const { originalPassword1 } = this.state;
    const inputEvent = e.nativeEvent as InputEvent;
    const isAllTextSelected = selectionStart === 0 && selectionEnd === value.length;
    if (!inputEvent || !inputEvent.inputType) {
      console.error("inputEvent or inputType is undefined");
      return;
    }
    let orig = 'zxcz';
    if (inputEvent.inputType === 'deleteContentBackward' || inputEvent.inputType === 'deleteContentForward') {
      if (isAllTextSelected) {
        orig = '';
      } else if (inputEvent.inputType === 'deleteContentBackward') {
        orig = originalPassword1.slice(0, originalPassword1.length - 1);
      }else if (inputEvent.inputType === "deleteContentForward") {
        orig = originalPassword1.slice(0, originalPassword1.length - 1);
      }
    } else if (inputEvent.inputType === 'insertText') {
      orig = originalPassword1 + value[value.length - 1];
    } else {
      orig = value;
    }
    this.setState({
      originalPassword1: orig,
      asterisk1: '*'.repeat(orig.length),
      confirmPassword: value,
    }, () => {
      this.validateConfirmPassword(this.state.originalPassword1);
    });
  };
    
    validateConfirmPassword = (confirmPassword: string) => {
      const passwordsMatch = confirmPassword === this.state.originalPassword;
      const error = passwordsMatch ? '' : "Passwords don't match";
    
      this.setState({
        validations: { ...this.state.validations, passwordsMatch },
        errors: { ...this.state.errors, confirmPassword: error },
      });
    };
    
    validateTerms = (agreeToTerms: boolean) => {
      this.setState({
        validations: { ...this.state.validations, termsAccepted: agreeToTerms },
        errors: { ...this.state.errors, terms: agreeToTerms ? '' : 'You must agree to the terms and conditions' },

      });
    }
    

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
  
      this.setState(
        {
          [name]: fieldValue,
          errors: { ...this.state.errors, [name]: '' }
        } as unknown as Pick<S, keyof S>,
        () => {
          switch (name) {
            case 'email':
              this.validateEmail(fieldValue as string);
              break;
            case 'password':
              this.validatePassword(fieldValue as string);
              break;
            case 'confirmPassword':
              this.validateConfirmPassword(fieldValue as string);
              break;
            case 'agreeToTerms':
              this.validateTerms(fieldValue as boolean);
              break;
          }
        }
      );
    };
  
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.validateConditions()) {
      return;
    }
    const header = {
      "Content-Type": "application/json",
    };
  
    const attrs = {
      "email": this.state.email,
      "password": this.state.originalPassword,
      "password_confirmation":this.state.originalPassword1,
      "tnc_status": this.state.agreeToTerms,
      "role_id":0
    };
  
    const httpBody = {
      "data": {
        "attributes": attrs,
      },
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailSignUpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlSignUp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    
  }; 
  toggleVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  toggleVisibility1 = () => {
    this.setState(prevState => ({
      showPassword1: !prevState.showPassword1
    }));
  }

  handleOpenModal = () => this.setState({ openModal: true });
  handleCloseModal = () => this.setState({ openModal: false });
  handleOpenTermsCondModal = () => this.setState({ openTermsCondModal: true });
  handleCloseTermsCondsModal = () => this.setState({ openTermsCondModal: false });
    
  handleLoginNavigate=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EmailAccountLoginBlock'
    );
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    )
    this.send(msg);
  }
  // Customizable Area End
}
