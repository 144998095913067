// Customisable Area Start
import React from "react";
import CreateEditProjectPortfolioController, {
  FileData,
  FilesType,
  Props,
} from "./CreateEditProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import {
  Box,
  Button,
  createTheme,
  Grid,
  Paper,
  styled,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import {
  ArrowBackIos,
  Close,
  ExpandLess,
  ExpandMore,
  FolderOpen,
  MoreVert,
  PersonAddOutlined,
  PictureAsPdfOutlined,
} from "@material-ui/icons";
import { file_upload } from "./assets";
import Dropzone, { DropzoneState } from "react-dropzone";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const StyledBox = styled(Box)({
  padding: "2rem 2.5rem",
  textAlign: "center",
  "& .pageTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  "& .backButton": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    cursor: "pointer",
    fontWeight: "600",
    width: "30vw",
    "& p": {
      fontSize: "0.875rem",
      color: "#334155",
      fontWeight: 600,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
      color: "#334155",
    },
  },
  "& .titleText": {
    color: "#0F172A",
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "2rem",
  },
  "& .buttonsBlock": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "30vw",
    justifyContent: "end",
  },
  "& .addMemberBtn": {
    display: "flex",
    gap: "0.5rem",
    cursor: "pointer",
    zIndex: 1,
  },
  "& .addMemberIcon": {
    width: "20px",
    height: "20px",
    color: "#237182",
  },
  "& .addMemberText": {
    fontWeight: 600,
    color: "#237182",
  },
  "& .saveDraftBtn": {
    padding: "10px 16px",
    borderRadius: "8px",
    background: "#E0EDF0",
    color: "#325962",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
    textTransform: "none",
    fontFamily: "Poppins",
  },
  "& .subTitle": {
    fontSize: "1.125rem",
    color: "#475569",
    lineHeight: "1.5rem",
    marginBottom: "2rem",
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: 0,
  },
  "& .typeItem": {
    padding: "1rem",
    background: "#FFF",
    borderRadius: "14px",
    "& .typeItemHeader": {
      display: "flex",
      alignItems: "center",
      gap: "0.75rem",
    },
    "& .typeTitle": {
      fontSize: "1.25rem",
      fontWeight: 700,
      lineHeight: "1.75rem",
      color: "#1E293B",
    },
    "& .folderIcon": {
      padding: "0.5rem",
      background: "#E0EDF0",
      color: "#237182",
      borderRadius: "8px",
    },
  },
  "& .selectTypeBtn": {
    width: "100%",
    background: "#237182",
    padding: "10px 16px",
    borderRadius: "8px",
    color: "#FFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "1rem",
    textTransform: "none",
    "&:disabled": {
      background: "none",
      color: "#64748B",
    },
  },
  "& .uploadHeader": {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
    "& p": {
      color: "#237182",
      fontWeight: 600,
    },
  },
  "& .dragText": {
    fontSize: "0.875rem",
    color: "#292524",
    fontWeight: 600,
    lineHeight: "1.375rem",
  },
  "& .fileFormatText": {
    color: "#475569",
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
  "& .fileBlock": {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008;",
    marginTop: "1rem",
    padding: "0.5rem",
    gap: "0.75rem",
    borderRadius: "8px",
    textAlign: "start",
    "& .fileLogo": {
      padding: "0.5rem",
      borderRadius: "4px",
    },
    "& .fileName": {
      fontSize: "0.875rem",
      lineHeight: "1.375rem",
      color: "#0F172A",
    },
    "& .fileSize": {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#64748B",
    },
    "& .fileError": {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#DC2626",
    },
    "& .moreIcon": {
      padding: "0.5rem",
      color: "#475569",
      cursor: "pointer",
    },
  },
  "& .fileCollapse": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1rem",
    "& .uploadedFilesText": {
      fontSize: "0.875rem",
      lineHeihgt: "1.375rem",
      color: "#334155",
    },
    "& .uploadedFilesCount": {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.5rem",
      color: "#237182",
    },
    "& .collapseBtn": {
      display: "flex",
      gap: "0.75rem",
      cursor: "pointer",
    },
    " & .collapseText": {
      color: "#237182",
      fontWeight: 600,
    },
  },
});
const types: { id: number; title: string; value: FilesType }[] = [
  {
    id: 1,
    title: "Architectural Plans",
    value: "architecturalPlans",
  },
  {
    id: 2,
    title: "Building Regulation Drawings",
    value: "buildingRegulationDrawings",
  },
  {
    id: 3,
    title: "Structural Calculations",
    value: "structuralCalculations",
  },
  {
    id: 4,
    title: "Drainage Drawings (Optional)",
    value: "drainageDrawings",
  },
  {
    id: 5,
    title: "M & E Drawings (Optional)",
    value: "meDrawings",
  },
];
// Customizable Area End

export default class ProjectCostPlanUpload extends CreateEditProjectPortfolioController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropZone = (type: FilesType) => {
    return (
      <Dropzone
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length > 0) {
            acceptedFiles.forEach((file) =>
              this.uploadCostPlanFile(file, type)
            );
          }
        }}
        data-test-id="dropzone"
      >
        {({ getRootProps, getInputProps }: DropzoneState) => (
          <Paper
            elevation={0}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              borderRadius: "8px",
              padding: "1rem",
              backgroundColor: "#F1F5F9",
              border: "2px dashed rgba(203, 213, 225, 1)",
              boxSizing: "border-box",
              marginTop: "1rem",
              cursor: "pointer",
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <>
              <Box className="uploadHeader">
                <img src={file_upload} alt="Upload Icon" />
                <Typography>Upload Files</Typography>
              </Box>
              <Typography className="dragText">
                Drag & Drop Files Here
              </Typography>
              <Typography className="fileFormatText">
                Acceptable formats: PDF & Images JPG, JPEG, PNG
              </Typography>
              <Typography className="fileFormatText">
                (Max size: 10MB)
              </Typography>
            </>
          </Paper>
        )}
      </Dropzone>
    );
  };
  // Customizable Area End
  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation} tabName="Projects">
          <StyledBox>
            <Box className="pageTitle">
              <Box className="backButton" onClick={this.goBack}>
                <ArrowBackIos />
                <Typography>Back</Typography>
              </Box>
              <Typography className="titleText">
                Upload Files for Specialist Cost Plan
              </Typography>
              <Box className="buttonsBlock">
                <Box className="addMemberBtn">
                  <PersonAddOutlined className="addMemberIcon" />
                  <Typography className="addMemberText">Add Member</Typography>
                </Box>
                <Button className="saveDraftBtn">Save Draft</Button>
                <Button
                  className="selectTypeBtn"
                  style={{ width: "auto" }}
                  disabled={this.isSubmitDisabled()}
                  data-test-id="next-btn"
                  onClick={this.navigateToScopes}
                >
                  Submit
                </Button>
              </Box>
            </Box>
            <Typography className="subTitle">
              Please upload the documents required for the cost estimation.
            </Typography>
            <Grid container spacing={2}>
              {types.map(
                (type: { id: number; title: string; value: FilesType }) => (
                  <Grid key={type.id} item md={4} sm={6} xs={12}>
                    <Box className="typeItem" key={type.id}>
                      <Box className="typeItemHeader">
                        <FolderOpen className="folderIcon" />
                        <Typography className="typeTitle">
                          {type.title}
                        </Typography>
                      </Box>
                      {this.renderDropZone(type.value)}
                      {(this.state.fileListExpanded[type.value]
                        ? this.state.costPlanFiles[type.value]
                        : this.state.costPlanFiles[type.value].slice(0, 2)
                      ).map((value: FileData) => (
                        <Box
                          className="fileBlock"
                          style={{
                            borderTop: value.error
                              ? "4px solid #DC2626"
                              : "none",
                          }}
                        >
                          <PictureAsPdfOutlined
                            className="fileLogo"
                            style={{
                              color: value.error ? "#DC2626" : "#237182",
                              background: value.error ? "#FEE2E2" : "#E0EDF0",
                            }}
                          />
                          <Box style={{ width: "100%" }}>
                            <Typography className="fileName">
                              {value.file_name}
                            </Typography>
                            {value.error ? (
                              <Typography className="fileError">
                                {value.error}
                              </Typography>
                            ) : (
                              <Typography className="fileSize">
                                {value.size}
                              </Typography>
                            )}
                          </Box>
                          {value.error ? (
                            <Close
                              className="moreIcon"
                              onClick={() =>
                                this.handleFileDelete(
                                  value.file_name,
                                  type.value
                                )
                              }
                              data-test-id="delete-btn"
                            />
                          ) : (
                            <MoreVert className="moreIcon" />
                          )}
                        </Box>
                      ))}
                      {this.state.costPlanFiles[type.value].length > 2 ? (
                        <Box className="fileCollapse">
                          <Typography className="uploadedFilesText">
                            Uploaded Files:{" "}
                            <span className="uploadedFilesCount">
                              {this.state.costPlanFiles[type.value].length}
                            </span>
                          </Typography>
                          {this.state.fileListExpanded[type.value] ? (
                            <Box
                              className="collapseBtn"
                              onClick={() =>
                                this.handleFilesCollapse(type.value)
                              }
                            >
                              <ExpandLess className="collapseText" />
                              <Typography className="collapseText">
                                Show less
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              className="collapseBtn"
                              onClick={() =>
                                this.handleFilesCollapse(type.value)
                              }
                            >
                              <ExpandMore className="collapseText" />
                              <Typography className="collapseText">
                                Show more
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      ) : null}
                    </Box>
                  </Grid>
                )
              )}
            </Grid>
          </StyledBox>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}
// Customisable Area End
