import React, { Component, ReactNode } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
const logoImg = require("./logo.svg");
const profileImg = require("./img.svg");
const logout = require("./exit.svg");
const alarm = require("./alarm.png");
const setting = require("./setting.png");
import MyProfileHeaderSidebar from "../../components/src/MyProfileHeaderSidebar"
import { Message } from "../../framework/src/Message"
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";
import { Box, Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const styleSheet = {
  navBar: {
    display: "flex",
    justifyContent: "space-between",
    height: "67px",
    alignItems: "center",
    padding: "0px 40px 0px 14px",
    borderBottom: "1px solid #E2E8F0"
  },
  logoNav: {
    display: "flex",
    alignContent: "center"
  },
  menubar: {
    display: "flex",
    listStyle: "none",
    gap: "26px",
    padding: "0px",
    margin: "0px 0px 0px 40px",
    alignItems: "center",
    ...(typeof window !== 'undefined' && window.innerWidth <= 600 && {
     display:"none"
    })
  },
  rightSideLogo: {
    width: "10px",
    height: "10px",
    border: "1px solid black"
  },
  rightLogo: {
    display: "flex",
    gap: "8px",
    alignItems: "center"
  },
  main: {
    display: "flex",
    height: "100%"
  },
  sidebar: {
    width: "100px",
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid #E2E8F0",
    justifyContent: "center"
  },
  sidebarMenu: {
    width: "84px",
    textAlign:"center",
    cursor: "pointer",
    borderRadius:"8px",
  },
  sidebarMenu2: {
    marginTop:"186px",
    width: "84px",
    textAlign:"center",
    cursor: "pointer",
    borderRadius:"8px",
  },
  list: {
    color: "#334155",
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    cursor: "pointer",
    padding: "8px 6px 8px 6px",
    borderRadius: "8px",
  },
  sideMenuText:{
    fontSize: "12px",
    fontWeight: 600,
    fontFamily:"Poppins",
    margin:"0px",
  },
  allSideBarMenu:{
    display: "flex",
    flexDirection: "column", 
    gap: "32px",
    marginTop:"213px"
  },
  logoutButton: {
    background: '#237182',
    color: '#FFFFFF',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '94px',
  } as const,
  cancelButton: {
    backgroundColor: '#E2E8F0',
    color: '#64748B',
    fontSize: '16px',
    fontFamily: 'Poppins',
    textTransform: 'none',
    height: '56px',
    borderRadius: "8px",
    width: '96px',
  }as const,
  title:{
      fontWeight:900,
      fontSize:"26px",
      color:"#0F172A",
      fontFamily: 'Poppins',
  }
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       flexGrow: 1,
//     },
//     personalImage: {

//     },
//     personalDetails: {

//     }
//   }),
// );

interface RouteParams {
  id: string;
}
interface MyProps {
  children?: ReactNode;
  showSidebar?: boolean;
  navigation?: any;
  tabName?: string;
};
interface MyState {
  tabHover: string;
  activeTab: string;
  openDialog:boolean
};

class MyProfileHeader extends Component<MyProps, MyState> {
  [x: string]: any;
  constructor(props: MyProps) {
    super(props);
    // this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationMessage),
    ];
    
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);

    this.state = {
      tabHover: "",
      activeTab: "",
      openDialog:false
    };
  }

  handleChangeHoverEffect(str: string) {
    this.setState({ tabHover: str });
  }

  componentDidMount(): void {
    this.setState({ activeTab: this.props.tabName ?? '' });
  }
  
  handleTabChange = (path: string, tabName: string) =>{
    this.props.navigation.navigate(path);
    this.setState({ activeTab: this.props.tabName ?? tabName });
  }

  handleNavigation(path: string) {
    this.props.navigation.navigate(path);
  }

  handleOpenDialog=()=>{
    this.setState({openDialog:true})
  }

  handleCloseDialog=()=>{
    this.setState({openDialog:false})
  }


  handleLogout = () => {
    localStorage.removeItem('token');
    this.setState({ openDialog: false });
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };




  
  render() {
    const tabHover: string = this.state.tabHover;
    const activeTab: string = this.state.activeTab;

    return (<>
      <div style={styleSheet.navBar}>
        <div style={styleSheet.logoNav}>
          <img src={logoImg} width="50px" height="50px" />
          <ul style={styleSheet.menubar}>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "Dashboard" ? "#E0EDF0" : "", color: tabHover == "Dashboard" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Dashboard")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Dashboard</li>
            <li style={{ ...styleSheet.list, backgroundColor: (tabHover == "Projects" || activeTab == "Projects") ? "#E0EDF0" : "", color: tabHover == "Projects" || activeTab == "Projects" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Projects")} onMouseLeave={() => this.handleChangeHoverEffect("")} onClick={()=>this.handleTabChange('ProjectPortfolio', "Projects")} >Projects</li>
            <li style={{ ...styleSheet.list, backgroundColor: (tabHover == "Users&Teams"||activeTab =="Users&Teams")  ? "#E0EDF0" : "", color: tabHover == "Users&Teams" ||activeTab =="Users&Teams" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Users&Teams")} onMouseLeave={() => this.handleChangeHoverEffect("")} onClick={()=>this.handleTabChange('TeamList',"Users&Teams")}  >Users & Teams</li>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "Catalogue&Estimations" ? "#E0EDF0" : "", color: tabHover == "Catalogue&Estimations" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Catalogue&Estimations")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Catalogue & Estimations</li>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "Invoice&Billing" ? "#E0EDF0" : "", color: tabHover == "Invoice&Billing" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Invoice&Billing")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Invoice & Billing</li>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "Scaling&Drawings" ? "#E0EDF0" : "", color: tabHover == "Scaling&Drawings" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Scaling&Drawings")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Scaling & Drawings</li>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "M&E" ? "#E0EDF0" : "", color: tabHover == "M&E" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("M&E")} onMouseLeave={() => this.handleChangeHoverEffect("")}>M & E</li>
            <li style={{ ...styleSheet.list, backgroundColor: tabHover == "Chat" ? "#E0EDF0" : "", color: tabHover == "Chat" ? "#237182" : "" }} onMouseEnter={() => this.handleChangeHoverEffect("Chat")} onMouseLeave={() => this.handleChangeHoverEffect("")}>Chat</li>
          </ul>
        </div>
        <div style={styleSheet.rightLogo}>
          <div> <img src={alarm} width="24px" height="24px" /></div>
          <div style={{ width: "32px", height: "32px", borderRadius: "50px", overflow: "hidden" }}> <img src={profileImg} style={{ objectFit: "cover" }} width="100%" height="100%" /></div>
          <div>
             <img src={logout} width="24px" height="24px" />
          </div>
          <MyProfileHeaderSidebar/>
        </div>
      </div>
      <div style={styleSheet.main}>
        {this.props.showSidebar ? <div style={styleSheet.sidebar as React.CSSProperties}>
          <div style={styleSheet.allSideBarMenu as React.CSSProperties}>
            <div style={{ ...styleSheet.sidebarMenu, backgroundColor: tabHover == "MyProfile" ? "#E0EDF0" : "" } as React.CSSProperties} onClick={() => { this.handleNavigation("/MyProfile") }} onMouseEnter={() => this.handleChangeHoverEffect("MyProfile")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 10.21 14.21 12 12 12C9.79 12 8 10.21 8 8C8 5.79 9.79 4 12 4C14.21 4 16 5.79 16 8ZM14 8C14 6.9 13.1 6 12 6C10.9 6 10 6.9 10 8C10 9.1 10.9 10 12 10C13.1 10 14 9.1 14 8ZM12 13C9.33 13 4 14.34 4 17V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V17C20 14.34 14.67 13 12 13ZM6 17.01V18H18V17C17.8 16.29 14.7 15 12 15C9.3 15 6.2 16.29 6 17.01Z" fill={tabHover == "MyProfile" ? "#237182" : "#64748B"} />
              </svg>
              <p style={{...styleSheet.sideMenuText, color:tabHover == "MyProfile" ? "#237182" : "#475569"}}>My Profile</p>
            </div>
            <div style={{ ...styleSheet.sidebarMenu, backgroundColor: tabHover == "ManageSubscriptions" ? "#E0EDF0" : "" } as React.CSSProperties} onClick={() => { this.handleNavigation("/ManageSubscriptions") }} onMouseEnter={() => this.handleChangeHoverEffect("ManageSubscriptions")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_23010_33986" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_23010_33986)">
                  <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9.2C9.41667 2.4 9.77917 1.91667 10.2875 1.55C10.7958 1.18333 11.3667 1 12 1C12.6333 1 13.2042 1.18333 13.7125 1.55C14.2208 1.91667 14.5833 2.4 14.8 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V11.7C20.6833 11.55 20.3583 11.4208 20.025 11.3125C19.6917 11.2042 19.35 11.125 19 11.075V5H5V19H11.05C11.1 19.3667 11.1792 19.7167 11.2875 20.05C11.3958 20.3833 11.525 20.7 11.675 21H5ZM5 19V5V11.075V11V19ZM7 17H11.075C11.125 16.65 11.2042 16.3083 11.3125 15.975C11.4208 15.6417 11.5417 15.3167 11.675 15H7V17ZM7 13H13.1C13.6333 12.5 14.2292 12.0833 14.8875 11.75C15.5458 11.4167 16.25 11.1917 17 11.075V11H7V13ZM7 9H17V7H7V9ZM12 4.25C12.2167 4.25 12.3958 4.17917 12.5375 4.0375C12.6792 3.89583 12.75 3.71667 12.75 3.5C12.75 3.28333 12.6792 3.10417 12.5375 2.9625C12.3958 2.82083 12.2167 2.75 12 2.75C11.7833 2.75 11.6042 2.82083 11.4625 2.9625C11.3208 3.10417 11.25 3.28333 11.25 3.5C11.25 3.71667 11.3208 3.89583 11.4625 4.0375C11.6042 4.17917 11.7833 4.25 12 4.25ZM18 23C16.6167 23 15.4375 22.5125 14.4625 21.5375C13.4875 20.5625 13 19.3833 13 18C13 16.6167 13.4875 15.4375 14.4625 14.4625C15.4375 13.4875 16.6167 13 18 13C19.3833 13 20.5625 13.4875 21.5375 14.4625C22.5125 15.4375 23 16.6167 23 18C23 19.3833 22.5125 20.5625 21.5375 21.5375C20.5625 22.5125 19.3833 23 18 23ZM17.5 21H18.5V18.5H21V17.5H18.5V15H17.5V17.5H15V18.5H17.5V21Z" fill={tabHover == "ManageSubscriptions" ? "#237182" : "#64748B"} />
                </g>
              </svg>
             <p style={{...styleSheet.sideMenuText, color:tabHover == "ManageSubscriptions" ? "#237182" : "#475569"}}> Manage Subscriptions </p>
            </div>
            <div style={{ ...styleSheet.sidebarMenu, backgroundColor: tabHover == "Schedule" ? "#E0EDF0" : "" } as React.CSSProperties} onClick={() => { this.handleNavigation("/Schedule") }} onMouseEnter={() => this.handleChangeHoverEffect("Schedule")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2C16.45 2 16 2.45 16 3ZM16 13H13C12.45 13 12 13.45 12 14V17C12 17.55 12.45 18 13 18H16C16.55 18 17 17.55 17 17V14C17 13.45 16.55 13 16 13ZM6 20H18C18.55 20 19 19.55 19 19V9H5V19C5 19.55 5.45 20 6 20Z" fill={tabHover == "Schedule" ? "#237182" : "#64748B"} />
              </svg>
              <p style={{...styleSheet.sideMenuText, color:tabHover == "Schedule" ? "#237182" : "#475569"}}>Schedule</p>
            </div>
            <div style={{ ...styleSheet.sidebarMenu, backgroundColor: tabHover == "Timesheet" ? "#E0EDF0" : "" } as React.CSSProperties} onClick={() => { this.handleNavigation("/Timesheet") }} onMouseEnter={() => this.handleChangeHoverEffect("Timesheet")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 8C23 9.1 22.1 10 21 10C20.82 10 20.65 9.98 20.49 9.93L16.93 13.48C16.98 13.64 17 13.82 17 14C17 15.1 16.1 16 15 16C13.9 16 13 15.1 13 14C13 13.82 13.02 13.64 13.07 13.48L10.52 10.93C10.36 10.98 10.18 11 10 11C9.82 11 9.64 10.98 9.48 10.93L4.93 15.49C4.98 15.65 5 15.82 5 16C5 17.1 4.1 18 3 18C1.9 18 1 17.1 1 16C1 14.9 1.9 14 3 14C3.18 14 3.35 14.02 3.51 14.07L8.07 9.52C8.02 9.36 8 9.18 8 9C8 7.9 8.9 7 10 7C11.1 7 12 7.9 12 9C12 9.18 11.98 9.36 11.93 9.52L14.48 12.07C14.64 12.02 14.82 12 15 12C15.18 12 15.36 12.02 15.52 12.07L19.07 8.51C19.02 8.35 19 8.18 19 8C19 6.9 19.9 6 21 6C22.1 6 23 6.9 23 8Z" fill={tabHover == "Timesheet" ? "#237182" : "#64748B"} />
              </svg>
              <p style={{...styleSheet.sideMenuText, color:tabHover == "Timesheet" ? "#237182" : "#475569"}}>Timesheet</p>
            </div>
            <div style={{ ...styleSheet.sidebarMenu, backgroundColor: tabHover == "Roles&Permission" ? "#E0EDF0" : "" } as React.CSSProperties} onClick={() => { this.handleNavigation("/Roles&Permission") }} onMouseEnter={() => this.handleChangeHoverEffect("Roles&Permission")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8.5C22 7.39 21.11 6.5 20 6.5H16V4.5C16 3.39 15.11 2.5 14 2.5H10C8.89 2.5 8 3.39 8 4.5V6.5H4C2.89 6.5 2.01 7.39 2.01 8.5L2 19.5C2 20.61 2.89 21.5 4 21.5H20C21.11 21.5 22 20.61 22 19.5V8.5ZM14 6.5V4.5H10V6.5H14ZM4 9.5V18.5C4 19.05 4.45 19.5 5 19.5H19C19.55 19.5 20 19.05 20 18.5V9.5C20 8.95 19.55 8.5 19 8.5H5C4.45 8.5 4 8.95 4 9.5Z" fill={tabHover == "Roles&Permission" ? "#237182" : "#64748B"} />
              </svg>
              <p style={{...styleSheet.sideMenuText, color:tabHover == "Roles&Permission" ? "#237182" : "#475569"}}>Roles & Permission</p>
            </div>
            <div  style={{ ...styleSheet.sidebarMenu2, backgroundColor: tabHover == "Logout" ? "#E0EDF0" : "" } as React.CSSProperties} onMouseEnter={() => this.handleChangeHoverEffect("Logout")} onMouseLeave={() => this.handleChangeHoverEffect("")}>
            <div  onClick={this.handleOpenDialog}>
             <img src={logout} width="24px" height="24px" />
          </div>
              <p style={{...styleSheet.sideMenuText, color:tabHover == "Logout" ? "#237182" : "#475569"}}>Log Out</p>
            </div>
            <Dialog PaperProps={{
            style: {
              width: '584px', 
              maxWidth: '584vw', 
              height: '245px', 
              maxHeight: '240vh', 
              borderRadius:"16px",
              padding:"40px 32px 40px 32px",
              color:"#FFFFFF"
            }
          }} open={this.state.openDialog} onClose={this.handleCloseDialog}>
            <Box  sx={{ padding: 2 }}>
              <Box style={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center"
              }}><Typography style={styleSheet.title} >Log out of your account?</Typography>  <Typography  onClick={this.handleCloseDialog} style={{
                color:"black"
              }}><Clear/></Typography></Box>
              
              <Typography style={{
                fontSize:"16px",
                fontWeight:400,
                paddingLeft:"",
                paddingRight:"80px",
                color:"#1E293B",
                fontFamily: 'Poppins',
                marginTop:"2px"
              }}>You'll be logged out of your account and will need to sign in again to access the projects.</Typography>
              <DialogActions style={{
                marginTop:"80px",
                gap:"10px"
              }}> 
              <Button style={styleSheet.cancelButton}  onClick={this.handleCloseDialog}>
                Cancel
              </Button>  
              <Button style={styleSheet.logoutButton} onClick={this.handleLogout} >
                Logout
              </Button></DialogActions>
           
            </Box>
          </Dialog>
          </div>
        </div> : null}
        <main style={{ backgroundColor: "#F0F5F7", width: "100%", overflow: "auto"}}>
        {this.props.children}
        </main>
      </div >
    </>)
  }
}
export default MyProfileHeader;