// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Modal,
  InputLabel,
  Select,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from '@material-ui/icons/Search';
import MyProfileHeader from "../../../components/src/MyProfileHeader"
import { rename,createIcon,unCheckIcon, checkedIcon} from "./assets";
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import TeamBuilderController, {
  Props,
} from "./TeamBuilderController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader
          navigation={this.props.navigation}
          tabName="Users&Teams"
        >
          <Loader loading={this.state.loading} />
          <Box data-test-id="txtInput" style={webStyle.flex}>
            <Box component="div" style={webStyle.OptionBox}>
              <Box component="div" style={webStyle.singleOptionBox}>
                <Box
                  data-test-id="button1"
                  onClick={this.handleToggle}
                  component="div"
                  style={
                    {
                      ...webStyle.PersonalInformationOption,
                      backgroundColor: this.state.toggle ? "#E0EDF0" : "",
                    } as React.CSSProperties
                  }
                >
                  Teams
                </Box>
                <Box
                  data-test-id="button2"
                  onClick={this.handleToggle}
                  component="div"
                  style={
                    {
                      ...webStyle.BusinessInformationOption,
                      backgroundColor: this.state.toggle ? "" : "#E0EDF0",
                    } as React.CSSProperties
                  }
                >
                  Members
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography style={webStyle.teamSize}>4 Teams</Typography>
                <Button
                  data-test-id="btnAddExample"
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#237182",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "10px 16px 10px 16px",
                    textTransform: "capitalize",
                  }}
                >
                  <span style={webStyle.createIcon}>
                    <img src={createIcon} alt="createIcon" />
                  </span>
                  Create Team
                </Button>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.flex}>
              <Box style={webStyle.searchBox}>
              <TextField
              variant="outlined"
              placeholder="Search here..."
              value=""
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{
                      color:"#94A3B8",
                      width:"20px",
                      height:"20px"}} />
                  </InputAdornment>
                ),
                style: {
                  width: '440px',
                  height: '32px', 
                  borderRadius:"8px"
                },
                classes: {
                  root: 'textFieldRoot',
                  input: 'textFieldInput',
                },
              }}
              style={{
                marginRight: '12px',
                backgroundColor:"white",
                height: '32px',
                borderRadius: '8px',
              }}
              />

            {!this.state.toggle && <Box style={{display:'flex', gap:'12px'}}>
                <CustomCheckbox
                data-test-id='role-checkbox'
                open={this.state.isRoleOpen}
                onOpen={() => this.setState({isRoleOpen: true})}
                onClose={() => this.setState({isRoleOpen: false})}
                IconComponent={()=>{
                  return this.state.isRoleOpen ? <ExpandLess onClick={()=>this.setState({isRoleOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                style={{width:'123px'}}
                multiple
                value={this.state.selectedRoles}
                name="role"
                onChange={this.handleChange}
                variant="outlined"
                placeholder="Role"
                displayEmpty
                renderValue={() => 'Role'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Owner', 'Manager', 'Employee'].map((role) => (
                  <CustomListItem key={role} value={role} style={{width: '123px', padding:'4px 10px'}}>
                    <Checkbox icon={<img src={unCheckIcon}/>} checkedIcon={<img src={checkedIcon}/>} style={{color:"#64748B"}} checked={this.state.selectedRoles.indexOf(role) > -1} />
                    <ListItemText primary={role} />
                  </CustomListItem>
                ))}
              </CustomCheckbox>
              <CustomCheckbox
                data-test-id='team-checkbox'
                open={this.state.isTeamOpen}
                onOpen={() => this.setState({isTeamOpen: true})}
                onClose={() => this.setState({isTeamOpen: false})}
                IconComponent={()=>{
                  return this.state.isTeamOpen ? <ExpandLess onClick={()=>this.setState({isTeamOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                style={{width:'136px'}}
                multiple
                value={this.state.selectedTeam}
                name="team"
                onChange={this.handleChange}
                variant="outlined"
                placeholder="Team"
                displayEmpty
                renderValue={() => 'Team'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Sales team', 'Marketing', 'Accounts Team', 'Sales team', 'Marketing Department'].map((team) => (
                  <CustomListItem key={team} value={team} style={{padding:'4px 10px'}}>
                    <Checkbox icon={<img src={unCheckIcon}/>} checkedIcon={<img src={checkedIcon}/>} checked={this.state.selectedTeam.indexOf(team) > -1} />
                    <ListItemText primary={team} />
                  </CustomListItem>
                ))}
              </CustomCheckbox>
              <CustomCheckbox
                data-test-id='approve-checkbox'
                open={this.state.isApproveOpen}
                onOpen={() => this.setState({isApproveOpen: true})}
                onClose={() => this.setState({isApproveOpen: false})}
                IconComponent={()=>{
                  return this.state.isApproveOpen ? <ExpandLess onClick={()=>this.setState({isApproveOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                style={{ width:'137px'}}
                multiple
                value={this.state.activated}
                onChange={this.handleChange}
                name="activated"
                variant="outlined"
                placeholder="Activated"
                displayEmpty
                renderValue={()=>'Activated'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Activated', 'Not Activated'].map((activate) => (
                  <CustomListItem key={activate} value={activate} style={{padding:'4px 10px'}}>
                    <Checkbox icon={<img src={unCheckIcon}/>} checkedIcon={<img src={checkedIcon}/>} checked={this.state.activated.indexOf(activate) > -1} />
                    <ListItemText primary={activate} />
                  </CustomListItem>
                ))}
              </CustomCheckbox>
              </Box>}
            </Box>

            <Box style={{
              alignItems:"center",
              display:"flex",
              justifyContent:"center"
            }}>
              <Box style={{ display: "flex", alignItems: "center" ,gap:"0"}}>
                <Box style={webStyle.sort}> <Typography variant="body1">Sort By:</Typography></Box>
                <Box >
                  <select style={{
                    border:"none",
                    color:"#237182",
                    fontWeight:600,
                    fontSize:"16px",
                    backgroundColor:"#f0f5f7",
                    marginBottom:"2px",
                    marginRight:"5px"


                  }}>
        <option value="apple">Name</option>
        <option value="banana">Name2</option>
        <option value="orange">Name3</option>
      </select>
                 
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.mt10}>
            <TableContainer style={webStyle.SubTableBox}>
              {this.state.toggle ? 
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={webStyle.TableTitleText}>
                      Team Name
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleText,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Members
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleText,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Date Created
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography style={webStyle.SubBoxy}>
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={webStyle.TableHeaderCenter}>
                        <Typography style={webStyle.SubBoxy}>
                          {row.members}
                        </Typography>
                      </TableCell>
                      <TableCell style={webStyle.TableHeaderCenter}>
                        <Typography style={webStyle.SubBoxy}>
                          {row.dataTime}
                        </Typography>
                      </TableCell>
                      <UpdatedBox>
                        <TableCell
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography
                            data-test-id="icon"
                            className="hoverEffect"
                            onClick={(event) => this.handleClick(event, row)}
                          >
                            <MoreVertIcon style={{ cursor: "pointer" }}/>
                          </Typography>

                          <Popover
                            style={webStyle.popup}
                            open={Boolean(this.state.anchorEl)}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              style: {
                                padding: "10px",
                                width: "213px",
                                boxShadow: "0px 4px 8px 0px #00000008",
                                marginLeft: "-3.5rem",
                                borderRadius: "8px",
                              },
                            }}
                          >
                            <MenuItem
                              style={{
                                ...webStyle.popMenu,
                                marginBottom: "10px",
                              }}
                              data-test-id="edit"
                            >
                              {" "}
                              <span style={webStyle.iconM}>
                                <EditIcon />
                              </span>{" "}
                              Edit Team
                            </MenuItem>
                            <MenuItem
                              style={{
                                ...webStyle.popMenu,
                                marginBottom: "10px",
                              }}
                              data-test-id="rename"
                              onClick={() => this.handleRenameModal()}
                            >
                              <span style={webStyle.iconM}>
                                <img src={rename} alt="rename" />
                              </span>{" "}
                              Rename Team
                            </MenuItem>
                            <MenuItem
                              style={webStyle.deleteIcon}
                              data-test-id="delete"
                              onClick={this.deleteTeamApi}
                            >
                              <span style={webStyle.iconM}>
                                <DeleteIcon />
                              </span>{" "}
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </UpdatedBox>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              :
              <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.TableTitleText}>Name</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Email</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Teams</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Role</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Status</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.membersList.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="SubDivC">{row.name}</TableCell>
                    <TableCell className="SubDivC">{row.email}</TableCell>
                    <TableCell className="SubDivC">{row.teams}</TableCell>
                    <TableCell className="SubDivC">
                    <Typography 
                      style={{
                        height: '20px',
                        width: 'fit-content',
                        borderRadius: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBlock: '2px',
                        paddingInline: '8px',
                        fontWeight: 700,
                        fontSize: '12px',
                        textTransform: 'uppercase',
                        ...this.getRoleStyles(row.role)
                      }}
                    >
                      {row.role}
                    </Typography>
                    </TableCell>
                    <TableCell className="SubDivC">{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            </TableContainer>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={webStyle.footer}
          >
            <Typography></Typography>
            <Box style={webStyle.footer2}>
              <ChevronLeftIcon style={webStyle.footerIcon} />{" "}
              <Typography style={webStyle.footerFont}>1</Typography>
              <ChevronRightIcon style={webStyle.footerIcon} />
            </Box>
            <Typography style={webStyle.footer3}>
              <Typography style={webStyle.footer3}>
                1 - {this.state.rows.length} of {this.state.rows.length} results
              </Typography>
            </Typography>
          </Box>
          <Modal
            open={this.state.isRenameModalOpen}
            onClose={() => this.handleRenameModal()}
            style={{ display: "flex" }}
          >
            <Box style={webStyle.renameModal}>
              <Box style={webStyle.modalHeader}>
                <Typography style={webStyle.renameText}>Rename Team</Typography>
                <Close
                  onClick={() => this.handleRenameModal()}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              <Typography style={{ color: "#1E293B" }}>
                Choose a new name for your team to help organize members
                effectively.
              </Typography>
              <Box mt="1.5rem">
                <InputLabel style={webStyle.renameInputLabel}>
                  Team Name
                </InputLabel>
                <RenameTextField
                  error={this.state.teamNameError}
                  value={this.state.teamName}
                  variant="outlined"
                  name="teamName"
                  data-test-id="teamName"
                  onChange={(e) => this.handleTeamNameChange(e.target.value)}
                  fullWidth
                  placeholder="Enter team name"
                  required
                  inputProps={{
                    style: {
                      fontFamily: "'Poppins', sans-serif",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
                {this.state.teamNameError && (
                  <Typography style={webStyle.errorText}>
                    This team name is already in use. Please choose a different
                    name.
                  </Typography>
                )}
              </Box>
              <Box style={webStyle.modalButtons}>
                <RenameButton
                  style={webStyle.cancelButton}
                  onClick={() => this.handleRenameModal()}
                >
                  Cancel
                </RenameButton>
                <RenameButton
                  disabled={!this.state.teamName}
                  onClick={this.editTeamNameApi}
                  data-test-id="rename-btn"
                >
                  Rename
                </RenameButton>
              </Box>
            </Box>
          </Modal>
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}


const CustomListItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#E0EDF0',
  },
  '&:hover': {
    backgroundColor: '#E0EDF0',
  },
});

const CustomCheckbox = styled(Select)({
  borderRadius:'8px',
  height: "32px",
  backgroundColor:'#fff',
  "&:hover":{
  backgroundColor: "#fff"
  },
  "& .MuiSvgIcon-root":{
    marginRight: '7px',
    height: '20px',
    width: "20px",
    cursor: 'pointer'
  },
  "& .MuiOutlinedInput-input":{
    padding:"7.5px 14px"
  }
});

const UpdatedBox = styled(Box)({
  "& .hoverEffect": {
    padding: "3px",
    "&:hover": {
      backgroundColor: "#D3E4E9",
      borderRadius: "50%",
    },
  },
});
const webStyle = {
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "50px",
    padding: "4px",
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px",
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "16px",
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "27px",
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 && {
      textAlign: "center",
    }),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 29px 0px 15px",
    marginTop: "20px",
    backgroundColor: "#f0f5f7",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  createTeamBtn: {
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    textTransform: "capitalize",
  },
  searchBox: {
    display:'flex'
  },
  sort: {
    marginRight: "15px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  mt10: {
    marginTop: "20px",
    paddingLeft: "40px",
    paddingRight: "80px",
  },
  teamSize: {
    marginRight: "15px",
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "20px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white",
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "33%",
  },
  TableHeaderCenter: {
    textAlign: "center" as "center",
  },
  TableContentBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  SubBoxy: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
  },

  SubUserImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  SubDivA: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
  },

  SubDivI: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "2px 8px",
    borderRadius: "40px",
  },
  deleteIcon: {
    color: "#DC2626",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  iconM: {
    marginRight: "10px",
    fontSize: "10px",
  },
  popMenu: {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  popup: {
    padding: "20px !important",
    marginLeft: "-5px !important",
  },
  createIcon: {
    marginRight: "10px",
  },
  footer: {
    padding: "10px",
    marginTop: "20rem",
  },
  footer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#94A3B8",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  footerFont: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
};

// Customizable Area End
